import React, { useState, useEffect } from "react";
import SalonGallary from "./SalonGallary";
import SalonDetails from "./SalonDetails";
import SalonDiscountCard from "../Ui/SalonDiscountCard";
import {
  getSalonDetails,
  getSalonDiscount,
} from "../../lib/salonProfileService";

import img1 from "../../assets/brand/brandGallary/img1.png";
import img2 from "../../assets/brand/brandGallary/img2.png";
import img3 from "../../assets/brand/brandGallary/img3.png";

import SalonMobGallary from "./SalonMobGallary";
import GreetingMessageCard from "../Ui/GreetingMessageCard";
import { getUserName } from "../../lib/userService";
import { useSelector } from "react-redux";
import store, { AppDispatch, RootState } from "../../store/store";
import ShareButton from "./ShareLink";
import { fetchShopDetails, fetchShopOffers } from "../../service/salonDetailsService";
import { ISalonDetailsProps } from "../../types/salonProfileTypes";
import { setSalonSlots } from "../../store/slices/salonSlotsSlice";
import { extractShopIdFromUrl } from "../../utils/salonProfile";

interface ISalonProfileMainProps {}

const mapurl = `https://www.google.com/maps/dir/Lucas+Academy+%26+Salon,+4th+Floor,+Fortune+Atrium,+Road+No.+36,+Jubilee+Hills,+opp.+Chutneys,+Hyderabad,+Telangana+500033/@17.4998891,78.3407503,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bcb91367d53f533:0xe5a255f8d03260af!2m2!1d78.4097527!2d17.430069?entry=ttu`;

const SalonProfileMain: React.FunctionComponent<ISalonProfileMainProps> = (
  props
) => {
  const salonInfo = useSelector((state: RootState) => state.salonDetails.data);
  const salonDiscount = useSelector((state:RootState)=>state.salonOffers.offers)
  const salonId = extractShopIdFromUrl();

  const [closeGreet, setCloseGreet] = useState<boolean>(false);
  const [extSalonInfo, setExtSalonInfo] = useState<ISalonDetailsProps>({
    salonName: "",
    about: "",
    salonAddress: "",
    salonImgs: [],
    mapLink: "",
    specilization: [],
    rating: 4.8,
    reviews: 234,
  });

  useEffect(() => {
    setTimeout(() => {
      setCloseGreet(true);
    }, 2000);
  }, []);

  // link for Share url
  const url = "https://stylin.in";
  const title = "Check out this awesome website!";

  let salonDiscount2 = getSalonDiscount();
  let isPopActive = useSelector((state: RootState) => state.custom.isPopOpen);
  let openSharePop = useSelector(
    (state: RootState) => state.custom.openSharePop
  );

  useEffect(() => {
    const dispatch: AppDispatch = store.dispatch;
    fetchShopDetails(dispatch, salonId);
      fetchShopOffers(dispatch,salonId);
  }, [salonId]);

  

  useEffect(() => {
    const salonInf: ISalonDetailsProps = {
      salonName: salonInfo?.shop_name || "Lucas - Salon & Academy",
      about: salonInfo?.about || "This Is Salon Description",
      salonAddress: "Jubilee Hills, Hyderabad",
      salonImgs: salonInfo?.profile_image_url || [img1, img2, img3],
      mapLink: url,
      specilization: salonInfo?.shop_attributes || [
        "Bridal makeup",
        "Groom makeup",
        "Outdoor Photoshoots",
      ],
      rating: salonInfo?.average_rating || 4.8,
      reviews: salonInfo?.review_count || 234,
    };
    setExtSalonInfo(salonInf);
  }, [salonInfo]);

  useEffect(() => {
    // Add the class to prevent body scrolling when popup is open
    if (isPopActive) {
      document.body.classList.add("no-scroll");
    }

    // Cleanup function to remove the class when popup is closed
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isPopActive]);

  console.log("salon offers", salonDiscount,salonDiscount2);

  return (
    <main className={`${isPopActive ? "-z-50" : ""} relative`}>
      <div
        className={`${
          openSharePop ? "fixed left-0 top-0 z-50 w-screen h-screen" : "hidden"
        }  `}
      >
        <ShareButton url={url} title={title} />
      </div>
      <section className={`${isPopActive ? "-z-50" : ""} w-full `}>
        <div
          className={` invi sible md:pt-[52px] lg:pt-10 pb-2.5 lg:pb-5  m-auto `}
        >
          <SalonGallary gallery={salonInfo ? extSalonInfo.salonImgs : []} />
          <SalonMobGallary gallery={salonInfo ? extSalonInfo.salonImgs : []} />
        </div>
        <div className=" relative w-full">
          <div className="">
            <div className="w-full md:w-[55%] lg:w-[60%]">
              <SalonDetails {...extSalonInfo} />
              <div className="  pt-2   lg:pt-3">
                <SalonDiscountCard offers={salonDiscount??[]} />
              </div>
            </div>
          </div>
          <div className="hidden md:flex absolute top-0 right-20 lg:right-0">
            <GreetingMessageCard />
          </div>
        </div>

        <div className={`${closeGreet && "hidden"} md:hidden pt-14 mr-0 `}>
          <GreetingMessageCard />
        </div>
      </section>
    </main>
  );
};

export default SalonProfileMain;
