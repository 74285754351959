import React, { useState } from 'react';
import { getBrandColor } from '../../lib/brandService';

interface IRadioPPProps {
    options: string[];
    name: string;
    onChange: (value: string) => void;
    selectedValue?: string;
}

const RadioPP: React.FC<IRadioPPProps> = ({
    options,
    name,
    onChange,
    selectedValue
}) => {
    const [selected, setSelected] = useState<string>(selectedValue || options[0]);
    const brandColor = getBrandColor();

    const handleRadioChange = (value: string) => {
        setSelected(value);
        onChange(value);
    };

    return (
        <div className="flex flex-col gap-4">
            {options.map((option, index) => (
                <label key={index} className="inline-flex items-center cursor-pointer">
                    <input
                        type="radio"
                        name={name}
                        value={option}
                        checked={selected === option}
                        onChange={() => handleRadioChange(option)}
                        className="hidden" // Hide the default radio button
                    />
                    <span
                        className={`w-5 h-5 flex items-center justify-center rounded-full border-2    transition-all duration-300`}

                        style={{
                            borderColor:  selected === option ?brandColor: '#848484'
                        }}
                    >
                        <span
                            className={`w-3 h-3 rounded-full   transform ${
                                selected === option ? 'scale-100' : 'scale-0'
                            } transition-transform duration-300 ease-out`}

                            style={{
                                backgroundColor: selected === option ? brandColor : 'transparent',
                            }}
                        ></span>
                    </span>
                    <span className="ml-2  ">{option}</span>
                </label>
            ))}
        </div>
    );
};

export default RadioPP;
