import React, { useState, useEffect } from "react";
import { IoIosStar } from "react-icons/io";
import ProgressBar from "../../Ui/ProgressBar";
import Checkbox from "../../Common/CheckboxPP";
import { getSalonReviews } from "../../../lib/salonReviewLib";
import { ReviewsState } from "../../../types/salonServicesTypes";
import {   useSelector } from "react-redux";
import store, { AppDispatch, RootState } from "../../../store/store";
import { fetchShopReviews } from "../../../service/salonReviewService";
import { extractShopIdFromUrl } from "../../../utils/salonProfile";

interface IRatingBarProps {
 
    ratingType: [string | number, (newValue: string | number) => void];
 
}

const RatingBar: React.FunctionComponent<IRatingBarProps> = (props) => {

  const salonId = extractShopIdFromUrl();

  
  const [isChecked, setIsChecked] = useState(false);
  const [ratingType, setRatingType] = useState<number[] >([1,2,3,4,5])

  const [percentage, setPercentage] = useState(0);
  let salonReviews =  useSelector((state:RootState)=>state.salonReviews)


useEffect(()=>{
  const dispatch: AppDispatch = store.dispatch;
  fetchShopReviews(dispatch,salonId,ratingType??0)
  
},[ratingType,salonId])




  const handleCheckboxChange = (checked: boolean) => {
    setIsChecked(checked);
    setPercentage(checked ? 100 : 0);
  };





  const ratingVal: Array<{
    id: string | number;
    title: string|number|null;
    des: string;
  }> = [
    {
      id: 1,
      title: salonReviews.averageRating,
      des: `${salonReviews.totalRatings}  Reviews`,
    },
    {
      id: "2",
      title: "88%",
      des: "Recommended",
    },
  ];


  const ratingCount: Array<{
    id: number;
    ratingNum: number | string;
    ratingPerc: number ;
  }> = [
    {
      id: 1,
      ratingNum: 5,
      ratingPerc: salonReviews.ratingCount?.rating5??0,
    },
    {
      id: 2,
      ratingNum: 4,
      ratingPerc: salonReviews.ratingCount?.rating4??0,
    },
    {
      id: 3,
      ratingNum: 3,
      ratingPerc: salonReviews.ratingCount?.rating3??0,
    },
    {
      id: 4,
      ratingNum: 2,
      ratingPerc: salonReviews.ratingCount?.rating2??0,
    },
    {
      id: 5,
      ratingNum: 1,
      ratingPerc: salonReviews.ratingCount?.rating1??0,
    },
  ];

  return (
    <section className="flex  gap-5 items-center pt-2 ">
      <div className="">
        {ratingVal.map((item, key) => (
          <div className="pb-2 lg:pb-3" key={item.id}>
            <div className="flex items-center  gap-2  pb-1 ">
              <h2 className="text-texthero lg:text-xl xl:text-[22px] font-medium">
                {item.title}
              </h2>
              <IoIosStar
                className={`${item.id != 1 && "hidden"} text-hero2 text-xl `}
              />
            </div>

            <h4 className="text-DARKEST_GREY_L1 text-[11px] lg:text-xs">{item.des}</h4>
          </div>
        ))}
      </div>
      <div className="flex flex-col w-full space-y-2 lg:space-y-[11px]">
        {ratingCount.map((item) => (
          <div className=" w- [55%] ">
            <div className="flex items-center">
              <div className="" onClick={()=>{
               setRatingType([...ratingType,+item.ratingNum])
                }}>

              <Checkbox
                label="Custom Checkbox"
                onChange={handleCheckboxChange}
                />
                </div>

              <p className="text-texthero text-xs lg:text-sm   pl-4   pr-2" >
                {item.ratingNum}
              </p>
              <ProgressBar progress={item.ratingPerc} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default RatingBar;
