// lib/shopDetailsApi.ts
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { GetShopDetailsData, GetShopIdVars,GetShopOffersData } from '../types/salonDetailsTypes';
import { AppDispatch } from '../store/store';
import { fetchStart,fetchSuccess,fetchFailure } from '../store/slices/salonDetailsSlice';
import {fetchOffersStart,fetchOffersSuccess,fetchOffersFailure} from '../store/slices/salonOffersSlice' 
import { APOLLO_CLIENT_URI } from '../config/config';
 
const client = new ApolloClient({
  uri: `${APOLLO_CLIENT_URI}/graphql`,
  cache: new InMemoryCache(),
});

const GET_SHOP_DETAILS_QUERY = gql`
 query GetShopDetails($shopId: ID!) {
  getShopDetails(shop_id: $shopId) {
    shop_id
    email_id
    is_email_verified
    phone_number
    is_phone_number_verified
    profile_image_url
    shop_name
    about
    shop_code
    shop_holiday
    shop_attributes
    shop_amenities
    customer_category
    shop_weekly_timings
    ratings {
      rating
      review
    }
    review_count
    average_rating
    shop_social_media_links {
      instagram
      linkedin
    }
    address
  }
}
`;


const GET_SHOP_OFFERS_QUERY =  gql`
query GetShopOffers($shopId: ID!) {
  getShopOffers(shop_id: $shopId) {
    shop_offer_id
    offer_percentage
    shop_id
    cart_value
    applicable_date
  }
}
`;

export const fetchShopDetails = async (
    dispatch: AppDispatch,
    shopId: string
  ): Promise<void> => {
    dispatch(fetchStart());
   
    try {
      const { data } = await client.query<GetShopDetailsData, GetShopIdVars>({
        query: GET_SHOP_DETAILS_QUERY,
        variables: { shopId },
      });
  
      dispatch(fetchSuccess(data.getShopDetails));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchFailure(error.message));
      } else {
        dispatch(fetchFailure("An unknown error occurred"));
      }
    }
  };

  export const fetchShopOffers = async (
    dispatch: AppDispatch,
    shopId: string
  ): Promise<void> => {
    dispatch(fetchOffersStart());
    try {
      const { data } = await client.query<GetShopOffersData, GetShopIdVars>({
        query: GET_SHOP_OFFERS_QUERY,
        variables: { shopId },
      });
      dispatch(fetchOffersSuccess(data.getShopOffers));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchOffersFailure(error.message));
      } else {
        dispatch(fetchOffersFailure("An unknown error occurred"));
      }
    }
  }