interface ProgressBarProps {
    progress: number;
}
  const ProgressBar: React.FC<ProgressBarProps> = ({ progress  }) => {
    return (
        <div className="w-full bg-[#E5E7EB] rounded-full h-2">
            <div
                className="h-2 rounded-full transition-all duration-500 bg-texthero"
                style={{
                    width: `${progress}%`,
                  
                    transition: 'width 0.5s ease-in-out',
                }}
            ></div>
        </div>
    );
  };

  export default ProgressBar
  