import React from "react";

import img1 from "../../assets/booking/status1.png";
import BookingStatusCard from "../Ui/BookingStatusCard";

import { useDispatch } from "react-redux";
import { gql, useMutation } from "@apollo/client";


import { setBookingFlow, setBookingState } from "../../store/slices/bookingSlice";
import { bookingStateValues } from "../../config/parameters";
import { CREATE_BOOKING_MUTATION } from "../../service/mutations/createBookingMutation";

const ConfirmBooking: React.FC = () => {
  const dispatch = useDispatch();
  const [createBooking] = useMutation(CREATE_BOOKING_MUTATION);

  const btnActions: Array<{
    id: number;
    btnName: string;
    btnAction: () => void;
    custClassName?: string;
  }> = [
    {
      id: 1,
      btnName: "Review Booking",
      btnAction: () => {
        dispatch(setBookingState(''));
        dispatch(setBookingFlow(false))
      },
      custClassName: "bg-white text-texthero",
    },
    {
      id: 2,
      btnName: "Confirm Booking",
      btnAction: async() => {
        dispatch(setBookingState(bookingStateValues[1]));
        try {
          const { data } = await createBooking({
            variables: {
          
                "shopId": "6",
                "professionalId": "3",
                "userId": "1",
                "bookingCustomerDetailId": "1",
                "slotTimings": [
                  "11:30 AM",
                  "12:00 PM",
                  "12:30 PM"
                ],
                "appointmentDate": "2024-09-12",
                "cost": 2000,
                "status": "accepted",
                "paymentStatus": "online",
                "packagesIds": null,
                "servicesIds": [
                  4,
                  5
                ]
       
            },
          });
    
          console.log("Booking created with ID:", data.createBooking.booking_id);
        } catch (error) {
          console.error("Error creating booking:", error);
        }
      },
      custClassName: "bg-texthero text-white",
    },
  ];

  const propValue = {
    title: " Confirm Booking",
    description: " Are you sure you want to confirm your   booking?",
    btnActions: btnActions,
    imgUrl: img1,
  };

  return (
    <section className=" ">
      <BookingStatusCard statusObj={propValue} />
    </section>
  );
};

export default ConfirmBooking;
