import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetAllSlots } from '../../types/bookingFlowTypes';

interface ISlotState {
  availableSlots: GetAllSlots[];
}

const initialState: ISlotState = {
  availableSlots: [],
};

const salonSlotSlice = createSlice({
  name: 'salonSlots',
  initialState,
  reducers: {
    setSalonSlots(state, action: PayloadAction<GetAllSlots[]>) {
      state.availableSlots = action.payload;
    },
  },
});

export const { setSalonSlots } = salonSlotSlice.actions;
export default salonSlotSlice.reducer;
