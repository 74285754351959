import React, { useState, useEffect } from "react";
import Carousel from "../Carousel/Carousel";
import SlotDateCard from "../Ui/SlotDateCard";
import SlotTimeCard from "../Ui/SlotTimeCard";
import OrderHeaderBar from "../Layout/OrderHeaderBar";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { GetAllSlots } from "../../types/bookingFlowTypes";
import { getDateAndDay, getMonthAndYear } from "../../utils/slotSchedule";

const TimeSchedule: React.FC = () => {
  const monthAndYearList = getMonthAndYear();
  const dateAndDayList = getDateAndDay();

 
  const getSlots = useSelector((state: RootState) => state.salonSlots.availableSlots);
  const selectedDate = useSelector((state:RootState)=>state.booking.selectedDate)
  const [fetchedSlots, setFetchedSlots] = useState<GetAllSlots[]>([]);

  useEffect(() => {
    setFetchedSlots(getSlots);
  }, [getSlots]);

  return (
    <section>
      <OrderHeaderBar title="Select Schedule" />

      <div className="relative pt-3 lg:pt-5  px-3 lg:px-10 ">
        <div className="flex items-center gap-1 text-texthero lg:text-lg font-medium lg:pb-3">
          <h3 className="">{monthAndYearList.month}</h3>
          <h4 className="">{monthAndYearList.year}</h4>
        </div>

        <Carousel scrollVal={600} isCustCls={true} custClassName="top-4 right-0 ">
          {dateAndDayList.map((slot, key) => (
            <div className={` relative flex flex-col items-center justify-end  `} key={key}>
              <SlotDateCard slotDates={slot} />
            </div>
          ))}
        </Carousel>
      </div>
      
      <div className="pt-12 px-2 lg:px-10 ">
        <h2 className="text-DARKEST_GREY_L1 font-medium">Morning Shift</h2>
        <div className="grid grid-cols-3 gap-3 lg:gap-0 lg:grid-cols-4">
          {fetchedSlots.length > 0 && fetchedSlots.map((slot, key) => (
            <SlotTimeCard key={key} slots={slot.morning_shift ?? []} />
          ))}
        </div>
      </div>

      <div className="pt-10 lg:pt-12 px-2 lg:px-10 ">
        <h2 className="text-DARKEST_GREY_L1 font-medium">Afternoon Shift</h2>
        <div className="grid grid-cols-3 gap-3 lg:gap-0 lg:grid-cols-4">
          {fetchedSlots.length > 0 && fetchedSlots.map((slot, key) => (
            <SlotTimeCard key={key} slots={slot.evening_shift ?? []} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TimeSchedule;
