import React from 'react';
import { CiDiscount1 } from "react-icons/ci";

interface IAddupProp {
    price?:number|null,
    disPercentage? : number|null
}
const AddupPrice :React.FC<IAddupProp> = ({price,disPercentage})=>{
    return (
            <div className=" flex items-center border border-blue bg-[#E9F2FF] px-4 py-2 xl:py-2.5 rounded-md  gap-3">
                <CiDiscount1 className='text-blue text-2xl'/>
            <h4 className="text-blue lg:w-max text-xs font-semibold">
            Add services worth ₹{price} more to avail {disPercentage}% off
            </h4>

        </div>
    )
}

export default AddupPrice;