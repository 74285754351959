import { gql } from '@apollo/client';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { APOLLO_CLIENT_URI } from '../../config/config';


const client = new ApolloClient({
  uri:  `${APOLLO_CLIENT_URI}/graphql`,
  cache: new InMemoryCache(),
});

export const ADD_BOOKING_CUSTOMER_DETAILS = gql`
  mutation AddBookingCustomerDetails(
    $userId: ID!, 
    $fullName: String, 
    $gender: String,
   
    $emailId: String, 
    $phoneNumber: String
  ) {
    addBookingCustomerDetails(
      user_id: $userId, 
      full_name: $fullName, 
      gender: $gender, 
   
      email_id: $emailId, 
      phone_number: $phoneNumber
    ) {
      booking_customer_detail_id
      full_name
      gender
      phone_number
      date_of_birth
      user_id
    }
  }
`;

export { client };  // Export the client as well
