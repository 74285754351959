
//validating EmailId
 const isValidEmail = (email:string) :boolean => {
    return /^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$/.test(email);
  };
  const isValidMobileNo = (mobileNo: number | null | string): string => {
    const mobileNoStr = mobileNo?.toString() || '';
    return /^[6-9]\d{9}$/.test(mobileNoStr) ? '' : 'Please enter a valid mobile number ';
  };

  const isValidOtp = (otp:number):string => {
    if (otp.toString().length !== 6) {
        return 'Please enter a valid OTP'
        }
        return ''
  }

//validating empty Inputs 
 

 const isValidInput = (input: string | number|null, inputType: string,inputName:string|number):   string => {
    if (inputType === 'text') {
        if (typeof input === 'string' && (input.trim() === '' || input.length < 1)) {
            return `Please enter a valid ${inputName}`
        }
    } else if (inputType === 'number') {
        if (typeof input === 'number' && isNaN(input)) {
            return 'Please enter a valid number';
        }
        
    }

    return '';
};


const formatTime = (totalMinutes: number): string => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours ? `${hours} hr${hours > 1 ? 's' : ''}` : ''} ${minutes ? `${minutes} min${minutes > 1 ? 's' : ''}` : ''}`.trim();
};


export {isValidEmail,isValidInput,isValidMobileNo,isValidOtp,formatTime}



