  function extractFirstLetterFromName(userName:string)  {
    const userDetails: string | null = userName
    if (userDetails) {
  
       
        const userName: string = userDetails
        return userName.charAt(0).toUpperCase();
    }
    return ''
     
  }
  
   

function extractUserGender ():string {
    let userGender:string = 'Male';
    return userGender
}

export {extractFirstLetterFromName,extractUserGender}