import React from 'react';
import ConfirmBooking from './ConfirmBookiing';
import BookingSuccess from './BookingSuccess';
import BookingError from './BookingError';
import BookingFeedback from './BookingFeedback';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
const BookingStatusMain :React.FC = ()=>{
    const {bookingState} = useSelector((state: RootState) => state.booking);
  

    return (
        <section className="">
        {bookingState==='pending'&& <ConfirmBooking/>}
        {bookingState==='confirmed'&&<BookingSuccess title='Booking Confirmed!'/>}
        {bookingState==='rescheduled'&&<BookingSuccess title='Booking Rescheduled!'/>}
        {bookingState==='error'&&<BookingError/>}
        {bookingState==='feedback'&&<BookingFeedback/>}
        </section>
    )
}

export default BookingStatusMain;