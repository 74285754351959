import  {createSlice,PayloadAction} from '@reduxjs/toolkit';
import { IinitialUsertype } from '../../types/userAuthTypes';



interface IAuthState {
    isLoginTrue:boolean,
    userAuthenticated:boolean,
    userName:string,
    profileFirstLetter:string,
    userDetails:IinitialUsertype
}

const initialState : IAuthState= {
    isLoginTrue:false,
    userAuthenticated:false,
    userName:'',
    profileFirstLetter:'',
    userDetails:{
        name:'',
        gender:'',
        dob:'',
        mobile:'',
        otp:''

    }
}


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoginState(state,action:PayloadAction<boolean>) {
            state.isLoginTrue = action.payload;
        },
        setUserAuth(state,action:PayloadAction<boolean>) {
            state.userAuthenticated = action.payload;
        },
        setUserName(state,action:PayloadAction<string>) {
            state.userName = action.payload;
        },
        setProfileFirstLetter (state,action:PayloadAction<string>) {
            state.profileFirstLetter = action.payload;

        },
        setUserDetails(state,payload:PayloadAction<IinitialUsertype>){
            state.userDetails = payload.payload;
        }
        
    }
})

export const {setLoginState,setUserAuth,setUserName,setProfileFirstLetter,setUserDetails} = authSlice.actions;
export default authSlice.reducer;