import React, { useEffect, useRef, useState } from "react";
import SearchItem from "../Common/SearchItemPP";
import DropdownPP from "../Common/DropdownPP";
import EmptyCart from "../Cart/EmptyCart";
import { extractUserGender } from "../../utils/userProfile";
import SalonFeatures from "./SalonFeatures";
import Cart from "../Cart/Cart";
import SalonCategories from "./SalonCategories";
import SalonServiceLayout from "../Layout/SalonServiceLayout";
import { useSelector } from "react-redux";
import store, { AppDispatch, RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";
import MobileCart from "../Cart/MobileCart";
import { getSalonDetails } from "../../lib/salonProfileService";
import {
  fetchPackages,
  fetchShopSubCategories,
} from "../../service/salonServicesService";
import SalonReview from "./SalonReview";
import SalonInfo from "./SalonInfo";
import { getSalonReviews } from "../../lib/salonReviewLib";
import { fetchShopReviews } from "../../service/salonReviewService";
import CustServicePopUpCard from "../Ui/CustServicePopUpCard";
import { extractShopIdFromUrl } from "../../utils/salonProfile";

const SalonServiceMain: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const cart = useSelector((state: RootState) => state.userCart.addedServices);
  const { userDetails } = useSelector((state: RootState) => state.login);

  const cartValues = {
    title: "LIST SUMMARY",
    ctaName: "Continue",
    onclick: () => {
      navigate("/lucas/hyderabad/orderDetails?shopId=1");
    },
  };
  const genderVal: string[] = ["Any", "Male", "Female"];
  const salonId = extractShopIdFromUrl();


  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedGender, setSelectedGender] = useState<string>(
    userDetails.gender
  );
  const [activeSection, setActiveSection] = useState<
    "services" | "packages" | "offers" | "reviews" | "about"
  >("services");
  const [ratingType, setRatingType] = useState<string>("");

  const servicesRef = useRef<HTMLDivElement>(null);
  const packagesRef = useRef<HTMLDivElement>(null);
  const offersRef = useRef<HTMLDivElement>(null);
  const reviewsRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);

  const sectionRefs = {
    services: servicesRef,
    packages: packagesRef,
    offers: offersRef,
    reviews: reviewsRef,
    about: aboutRef,
  };

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting); // Set to true when visible, false when not visible
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    const currentSection = servicesRef.current;
    if (currentSection) {
      observer.observe(currentSection);
    }

    // Cleanup function to unobserve when component unmounts
    return () => {
      if (currentSection) {
        observer.unobserve(currentSection);
      }
    };
  }, []);
 
  // Function to handle scrolling to specific section
  const scrollToSection = (sectionId: keyof typeof sectionRefs) => {
    const sectionRef = sectionRefs[sectionId];
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const handleGenderChange = (value: string) => {
    setSelectedGender(value);
  };

  useEffect(() => {
    setSelectedGender(userDetails.gender);
  }, [userDetails.gender]);

  useEffect(() => {
    const dispatch: AppDispatch = store.dispatch;

    fetchPackages(dispatch, salonId);
    fetchShopSubCategories(dispatch, salonId, selectedGender.toLowerCase());
  }, [salonId, selectedGender]);

  useEffect(() => {
    const handleScroll = () => {
      const sectionPositions = Object.keys(sectionRefs).map((section) => ({
        id: section as keyof typeof sectionRefs, // Cast section as keyof sectionRefs
        offsetTop:
          sectionRefs[section as keyof typeof sectionRefs].current?.offsetTop ||
          0,
      }));

      const currentScrollPos = window.scrollY + 100;

      const currentSection = sectionPositions.find(
        (section) =>
          currentScrollPos >= section.offsetTop &&
          currentScrollPos <
            section.offsetTop + sectionRefs[section.id]?.current?.clientHeight!
      );

      if (currentSection) {
        setActiveSection(currentSection.id);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let salonServices = useSelector(
    (state: RootState) => state.salonServices.subCategories
  );
  let salonPackages = useSelector(
    (state: RootState) => state.salonPackages.packages
  );

  let isPopActive = useSelector(
    (state: RootState) => state.custom.isPopOpen
  );
  let isCustServPop = useSelector((state:RootState)=>state.custom.openCustServicePop)

  // console.log('salon service',salonServices?.map(cat=>cat.subcategories.map(item=>item.customizations)))

  return (
    <main className={`${isPopActive ? "md:-z-50" : "z-auto"} relative w-full`}>
      <div className={`${isCustServPop ? "fixed top-0 left-0" : "hidden"}   `}>
        <CustServicePopUpCard />
        {/* <CustPackagePopUpCard/> */}
      </div>
      <section className="w-full flex md:gap-6 lg:gap-20 items-start">
        <div className="w-full md:w-[55%] lg:w-[60%]">
          <div
            className={`${
              isPopActive ? "-z-50" : "z-30  "
            } sticky top-0   bg-white`}
          >
            <form className={``}>
              <div className="flex gap-2 md:gap-5 lg:gap-3 pt-3 lg:pt-6">
                <SearchItem value={searchValue} onChange={handleSearchChange} />
                <DropdownPP
                  custClsName="md:w-40 lg:w-36"
                  isSpecFunType="gender"
                  dropDefVal={selectedGender}
                  options={genderVal}
                  value={selectedGender}
                  onChangeFun={handleGenderChange}
                />
              </div>
              <button type="submit" className="hidden">
                Submit
              </button>
            </form>
              <div className={` pt-6`}>
              <SalonFeatures
                onIconClick={scrollToSection}
                activeSection={activeSection} // Pass activeSection to SalonFeatures
              />
            </div>
          </div>
          <div
            className={`${isPopActive ? "-z-50" : "z-20 bg-white " } ${
              isVisible && "sticky "
            }  top-[6.6rem] lg:top-32 h-full z-40  lg:pt-5 md:pb-8 lg:pb-7`}
          >
            <SalonCategories />
          </div>

          <div
            className={`${
              salonServices?.length === 0
                ? "hidden"
                : "lg:pt-5 pb-12 md:pb-20 lg:pb-40"
            }  `}
            ref={servicesRef}
            id="services"
          >
            <SalonServiceLayout
              isService={true}
              services={salonServices ?? []}
            />
          </div>
          <div
            className={`  pb-12 md:pb-20 lg:pb-40 xl:pt-44`}
            ref={packagesRef}
            id="packages"
          >
            <SalonServiceLayout services={salonPackages ?? []} />
          </div>
          <div className="pt-12 lg:pt-44" ref={reviewsRef} id="reviews">
            <SalonReview />
          </div>
          <div className=" md:pt-10  lg:pt-40" ref={aboutRef} id="about">
            <SalonInfo />
          </div>
        </div>

        <div
          className={`${
            isPopActive ? "-z-50" : "z-40"
          }  hidden md:block lg:space-y-14  sticky top-0 lg:w-[30%] pt-3 lg:pt-6`}
        >
          {cart.length > 0 ? <Cart summary={cartValues} /> : <EmptyCart />}
        </div>
          
        <div className="md:hidden fixed bottom-1 px-1 left-0 w-full">
          {cart.length > 0 && <MobileCart summary={cartValues} />}
        </div>
      </section>
    </main>
  );
};

export default SalonServiceMain;
