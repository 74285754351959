
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./components/Layout/ScrollToTop";
import AppRoutes from "./routes/Routes";
import { Provider } from "react-redux";
import Login from "./components/Auth/Login";


import { useSelector } from "react-redux";
import store,{RootState} from "./store/store"; 
import { ApolloProvider } from "@apollo/client";
import { client } from "./service/mutations/userCustomerDetailsMutationService";

const App: React.FunctionComponent = () => {

 

  
  if (window.location.pathname === "/") {
    window.location.href = "/lucas/hyderabad";
  }

  return (
    <ApolloProvider client={client}>
      
    <Provider store={store}>
      <Router>
        <ScrollToTop>
         
          <AppRoutes />
        </ScrollToTop>
      </Router>
    </Provider>
    </ApolloProvider>
  );
};

export default App;
