import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IoManSharp, IoWoman } from "react-icons/io5";
import { IDropdownPPProps } from "../../types/userAuthTypes";

const DropdownPP: React.FC<IDropdownPPProps> = ({
  custClsName,
  onChangeFun = () => {},
  options,
  isSpecFunType,
  dropDefVal,
  value,
}) => {
  const [isDropOpen, setIsDropOpen] = useState<boolean>(false);

  const handleDropOpn = () => {
    setIsDropOpen(!isDropOpen);
  };

  const handleSelect = (option: string) => {
    if (onChangeFun) {
      onChangeFun(option);
    }
    setIsDropOpen(false); // Close dropdown after selection
  };

  return (
    <div
      className={`${custClsName} flex items-center md:gap-3 border border-DARKEST_GREY_L3 rounded-md px-2.5 md:px-4 py-2 relative cursor-pointer z-20`}
      onClick={handleDropOpn}
    >
      <div className={`${isSpecFunType === "gender" ? "flex md:hidden" : "hidden"} items-center`}>
        <IoManSharp />
        <IoWoman className="-ml-1.5" />
      </div>
      <p className={`${isSpecFunType === "gender" ? "hidden md:block" : ""} w-max text-DARKEST_GREY_L1 font-medium`}>
        {value==''?dropDefVal:value}
      </p>
      <div className="flex flex-1" />
      {isDropOpen ? (
        <FaChevronUp className={`${isSpecFunType === "gender" ? "" : ""} hidden md:block text-DARKEST_GREY_L3`} />
      ) : (
        <FaChevronDown className={`hidden md:block text-DARKEST_GREY_L3`} />
      )}

      <div
        className={`${isDropOpen ? "" : "hidden"} ${
          isSpecFunType === "gender" ? "-left-24 md:left-0 w-max md:w-full" : "left-0 w-full"
        } top-[42px] bg-[#FEFFFF] absolute rounded-md`}
        style={{
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
        }}
      >
        {options?.map((option, index) => (
          <div className="hover:bg-DARKEST_GREY_L4" onClick={() => handleSelect(option)} key={index}>
            <p className="text-sm md:text-base px-3 pt-3 pb-3 md:pb-6">
              {`${isSpecFunType === "gender" ? "For " : ""}${option}`}
            </p>
            <hr className={`${index + 1 === options.length && "hidden"} text-DARKEST_GREY_L3`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropdownPP;
