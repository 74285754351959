import * as React from 'react';
import emptyCartImg from '../../assets/custom/emptyCart.png'

interface IEmptyCartProps {
}

const EmptyCart: React.FunctionComponent<IEmptyCartProps> = (props) => {
  return (
    <div className=" w-full gradBorder rounded-2xl md:p-5 lg:py-6 bg-white">
        <div className="">
            <h1 className="lg:text-xl xl:text-2xl font-semibold text-center">
            Add Services
            </h1>
            <p className="text-DARKEST_GREY_L1 lg:w-full  text-center md:text-sm xl:text-base md:pt-4 md:pb-11 xl:pb-12">
            You must add services to your <br className="hidden lg:block" /> booking <br className="lg:hidden" /> to proceed.
            </p>
        </div>
        <img src={emptyCartImg} alt="Empty Cart Image" className='m-auto' />
    </div>
  );
};

export default EmptyCart;
