import React, { useState } from "react";
import EmojiPicture from "../Svg/EmojiSvg"; // Ensure path is correct
import { emojiData } from "../../data/emojiData";
import InputPP from "../Common/InputPP";
import { useNavigate } from "react-router-dom";


const BookingFeedback: React.FC = () => {
  const [activeEmoji, setActiveEmoji] = useState<number | null>(null);
  const [textValue, setTextValue] = useState<string>("");

  const navigate = useNavigate()
  const handleEmojiClick = (index: number) => {
    setActiveEmoji(index);
  };

  return (
    <section className="bg-white relative md:w-max rounded-xl md:rounded-2xl h-72 md:h-96 xl:h-[27rem] px-5 md:px-20 lg:px-28 xl:px-32 flex flex-col justify-center items -center">
      <h3 className=" w-72  md:w-max text-center md:text-start text-sm md:text-base font-medium text-texthero ">
        Share your valuable feedback to help us improve your experience
      </h3>
      <div className=" flex items-center space-x-3 md:space-x-9   pt-4">
        {emojiData.map((emoji, index) => (
          <div className="flex flex-col items-center" onClick={()=>{
            setTimeout(()=>{
              window.location.href='/'
            },2000)
          }}>
            <EmojiPicture
              key={index}
              gifSrc={emoji.gifSrc}
              altText={emoji.altText}
              size={40}
              isActive={activeEmoji === index}
              onClick={() => handleEmojiClick(index)}
            />
            <h3 className="text-xs text-DARKEST_GREY_L2 text-center">
              {emoji.title}
            </h3>
          </div>
        ))}
      </div>
      <div className="md:w-96">
        <InputPP
          label="Text Input"
          type="text"
          placeholder="Share your feedback"
          istextArea={true}
          custClassName="mt-3"
          value={textValue}
          onChange={(value) => setTextValue(value)}
        />
      </div>
    
    </section>
  );
};

export default BookingFeedback;
