import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

// Wheel data
const data = [
  { option: 'Prize 1' },
  { option: 'Prize 2' },
  { option: 'Prize 3' },
  { option: 'Prize 4' },
  { option: 'Prize 5' },
  { option: 'Prize 6' },
];

const SpinTheWheel: React.FC = () => {

    const ratingValue = useSelector((state:RootState)=>state.custom.serviceRating)
    const showSpin = useSelector((state:RootState)=>state.custom.showSpin)

  const [rotation, setRotation] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [selectedPrize, setSelectedPrize] = useState<string | null>(null);

  const handleSpinClick = () => {
    if (isSpinning) return;

    // Generate random rotation (between 2000 and 3000 degrees)
    const randomRotation = Math.floor(Math.random() * 1000) + 2000;
    const totalRotation = rotation + randomRotation;
    setRotation(totalRotation); // Ensure it rotates clockwise continuously
    setIsSpinning(true);

    // Calculate which prize is under the arrow
    setTimeout(() => {
      const normalizedRotation = totalRotation % 360;
      const prizeIndex = Math.floor((360 - normalizedRotation) / 60) % 6;
      const closestPrize = data[prizeIndex].option;

      setSelectedPrize(closestPrize);
      setIsSpinning(false);
    }, 5000); // Matches animation duration (5 seconds)
  };

  return (
    <div className={` ${+ratingValue>3&&showSpin?'flex flex-col items-center justify-center':"hidden"}  py-8 md:py-14 lg:py-20   `}>
      {/* Arrow Indicator */}
      <div className="arrow-indicator">
        <span>▼</span>
      </div>

      {/* Wheel container */}
      <div
        className="wheel   "
        style={{
          transform: `rotate(${rotation}deg)`,
          transition: 'transform 5s cubic-bezier(0.33, 1, 0.68, 1)', // Smoother easing
        }}
      >
        {data.map((item, index) => (
          <div
            key={index}
            className="wheel-section text-center"
            style={{
              transform: `rotate(${index * 60}deg)`, // Dividing wheel into equal sections
            }}
          >
            <span
              className="wheel-text text-center flex justify-center items-center"
              style={{
                transform: `rotate(-${index * 60}deg)`, // Align text in the center of each section
              }}
            >
              {item.option}
            </span>
          </div>
        ))}
      </div>

      {/* Spin button */}
      <button
        className={`mt-8 px-6 py-3 bg-texthero text-white rounded-lg font-bold transition-all duration-300 ${isSpinning ? 'cursor-not-allowed' : ''}`}
        onClick={handleSpinClick}
        disabled={isSpinning}
      >
        Spin the Wheel!
      </button>

      {/* Display selected prize */}
      {selectedPrize && (
        <div className="mt-4 text-xl font-bold text-gray-700">
          🎉 Selected Prize: {selectedPrize} 🎉
        </div>
      )}
    </div>
  );
};

export default SpinTheWheel;
