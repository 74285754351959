import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustServicePop, SubCategory } from '../../types/salonServicesTypes';


interface ICustServiceProp {
  customizations: CustServicePop[];
  
category_name:string

}

 
interface salonDetailsState {
  loading: boolean;
  error: string | null;
  subCategories: SubCategory[] | null;
  custServices : ICustServiceProp|null;
  serviceType:string
}

const initialState: salonDetailsState = {
  loading: true,
  error: null,
  subCategories: null,
  custServices:null,
  serviceType:'Short Hair'
};

const salonDetailsSlice = createSlice({
  name: 'salonDetails',
  initialState,
  reducers: {
    fetchSubCategoriesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchSubCategoriesSuccess(state, action: PayloadAction<SubCategory[]>) {
      state.loading = false;
      state.subCategories = action.payload;
    },
    fetchSubCategoriesFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchCustServices (state,action:PayloadAction<ICustServiceProp>) {
      state.loading = false;
      state.custServices = action.payload;
    },
    fetchServiceType (state,action:PayloadAction<string>) {
      state.loading = false;
      state.serviceType = action.payload;
    }
  },
});

export const {
  fetchSubCategoriesStart,
  fetchSubCategoriesSuccess,
  fetchSubCategoriesFailure,
  fetchCustServices,
  fetchServiceType
} = salonDetailsSlice.actions;

export default salonDetailsSlice.reducer;
