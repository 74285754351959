import React, { useState, useEffect } from "react";
import { getBrandColor, getBrandLogo } from "../../lib/brandService";
import { FaAngleDown } from "react-icons/fa6";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import { setLoginState } from "../../store/slices/authSlice";
import UserProfileCard from "../Ui/UserProfileCard";
import { extractFirstLetterFromName } from "../../utils/userProfile";

export interface NavProps {}

const NavBar: React.FC<NavProps> = () => {
  const [extBrandColor, setExtBrandColor] = useState<string>("#020202");
  const [extBrandLogo, setExtBrandLogo] = useState<string>("");
  const [showProfile, setShowProfile] = useState<boolean>(false)
  

  const dispatch = useDispatch();
  const isLoggedIn = useSelector(
    (state: RootState) => state.login.userAuthenticated
  );
  const {userName} = useSelector((state:RootState)=>state.login)

  const firstLetter =  extractFirstLetterFromName(userName);


  useEffect(() => {
    const fetchBrandData = async () => {
      try {
        const color = getBrandColor();
        const logo = getBrandLogo();
        setExtBrandColor(color);
        setExtBrandLogo(logo);
      } catch (error) {
        console.error("Error fetching brand data:", error);
      }
    };

    fetchBrandData();
  }, []);
 

  return (
    <nav
      className={`layout layout-md layout-lg layout-xl py-4 md:py-2 lg:py-5 lg:h-[76px] xl:h-[84px] w-full flex items-center`}
      style={{ backgroundColor: extBrandColor }}
    >
      <div>
        <img src={extBrandLogo} alt="Brand Logo" className="w-11 md:w-10 lg:w-full" />
      </div>
      <div className="flex flex-1" />
      <div
        className="relative md: p-2 flex items-center gap-2 rounded-[40px] cursor-pointer "
        style={{ background: "rgba(255, 255, 255, 0.20)" }}
        onClick={()=>{
          isLoggedIn&&   setShowProfile(!showProfile)
        }}
      >
        {isLoggedIn ? (
          <div className="bg-hero2 w-5 h-5 lg:w-7 lg:h-7 rounded-full flex items-center justify-center" >
            <p className="text-center">{firstLetter}</p>
          </div>
        ) : (
          <p
            className="cursor-pointer font-semibold px-2"
            onClick={() => {
              dispatch(setLoginState(true));
            }}
          >
            Login
          </p>
        )}
        <FaAngleDown className={`${!isLoggedIn && "hidden"}`} />

        {isLoggedIn && (
          <div className={`${!showProfile&&'hidden'} absolute  top-10 lg:top-12 right-0 z-50`}>
            <UserProfileCard userProfileLetter={firstLetter} />
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
