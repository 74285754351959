 
export const bookingStateValues :Array<string> =['pending','confirmed','rescheduled','error','feedback']
   
   
   
//     {
//         id:1,
//         stateVal:'pending'
//     },
//     {
//         id:2,
//         stateVal:'confirmed'
//     },
//     {
//         id:3,
//         stateVal:'rescheduled'
//     }, {
//         id:4,
//         stateVal:'error'
//     },
//     {
        
//         id:5,
//         stateVal:'feedback'
//     }
// ]