import * as React from "react";
import NavBar from "../components/Layout/NavBar";
import Footer from "../components/Layout/Footer";
import SalonServiceMain from "../components/SalonServices/_SalonServiceMain";
import SalonProfileMain from "../components/SalonProfile/_SalonProfileMain";

import { useSelector } from "react-redux";
import { RootState } from "../store/store";

export interface IAppProps {}

const SalonProfile: React.FC<IAppProps> = () => {

  const isLoginPop = useSelector((state:RootState)=>state.login.isLoginTrue)

  return (
    <React.Fragment>
      <main className="">
        <NavBar />

        <section className={`${isLoginPop&&'-z-30  '} layout layout-md layout-lg layout-xl  pb-80  relative    `}>
          {/* --------- Salon Profile --------- */}
          <SalonProfileMain />

          {/* ------------- Salon Services ----------- */}
          <section className="pt-5 md:pt-10 lg:pt-14 w-full">
            <SalonServiceMain />
          </section>
        </section>

        <Footer />
      </main>
    </React.Fragment>
  );
};

export default SalonProfile;
 