import store, { RootState, AppDispatch } from '../store/store';
import { fetchShopDetails, fetchShopOffers } from '../service/salonDetailsService';
import { ISalonDetailsProps } from '../types/salonProfileTypes';

//salon Default Images 
import img1 from '../assets/brand/brandGallary/img1.png'
import img2 from '../assets/brand/brandGallary/img2.png'
import img3 from '../assets/brand/brandGallary/img3.png'
import { SalonOffers } from '../types/salonDetailsTypes';

const url = `https://www.google.com/maps/dir/Lucas+Academy+%26+Salon,+4th+Floor,+Fortune+Atrium,+Road+No.+36,+Jubilee+Hills,+opp.+Chutneys,+Hyderabad,+Telangana+500033/@17.4998891,78.3407503,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bcb91367d53f533:0xe5a255f8d03260af!2m2!1d78.4097527!2d17.430069?entry=ttu`;

export async function initializeSalonInfo() {
  const dispatch: AppDispatch = store.dispatch;

  // Dispatch action to fetch shop details
  // await fetchShopDetails(dispatch, "6");

  // Get the updated state from the store
  const state: RootState = store.getState();
  const { data } = state.salonDetails;

  // Create the salonInfo object based on the fetched data or use fallback data
  const salonInfo: ISalonDetailsProps = {
    salonName: data?.shop_name || "Lucas - Salon & Academy",
    about:data?.about||'This Is Salon Description',
    salonAddress:  "Jubilee Hills, Hyderabad",
    salonImgs:data?.profile_image_url||[img1,img2,img3] ,
    mapLink: url,
    specilization: data?.shop_attributes || ["Bridal makeup", "Groom makeup", "Outdoor Photoshoots"],
    rating: data?.average_rating || 4.8,
    reviews: data?.review_count || 234,
  };

  return salonInfo;
}

 
export async function  initializeSalonOffers () {
  const dispatch: AppDispatch = store.dispatch;

  await fetchShopOffers(dispatch,"1");

  const state:RootState = store.getState();
  const {offers} = state.salonOffers


  const salonOffers :SalonOffers[] =offers?offers:[]
  return salonOffers;
}



// Things to do next : 

// -Tags for review section 
// - Need to Write Lamda function to automate the images 
// - shop_color api schema adding (api changes)
// -Edit the ShopDetails api to get brand logo (add it in s3 bucket)
// - create ui for see all photoes 
// - add address 