import axios from 'axios';

 
const API_ENDPOINT = process.env.REACT_APP_API_URL;
  

export const sendOtp = async (phoneNumber: string, is_partner: boolean): Promise<any> => {
const endpoint = "/auth/signUpWithNumber"
 
    const requestData = {
    phoneNumber: phoneNumber,
    is_partner: is_partner,
    // Add other fields as needed
  };

  try {
    const res = await axios.post(`${API_ENDPOINT}${endpoint}`, requestData, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET, POST, OPTIONS"
      }
    });

    if (res.status === 200) {
      return true;
    
    }
  } catch (error) {
    console.error('Login error:', error);
    return false
   
  }
};
export const validateOtp = async (phoneNumber: string,is_partner: boolean,otp:string,name:string,dob:string): Promise<any> => {
const endpoint = "/auth/verifyNumberSignUp"
 
    const requestData = {
    phoneNumber: phoneNumber,
    is_partner: is_partner,
    otp: otp,
  
  };

  try {
    const res = await axios.post(`${API_ENDPOINT}${endpoint}`, requestData, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET, POST, OPTIONS"
      }
    });

    if (res.status === 200) {
    await updateUsreDetails(name,phoneNumber,dob)

      return true;

     
    }
  } catch (error) {
    console.error('Otp verification Failed:', error);
    return false;
    
  }
};

export const updateUsreDetails = async (name: string,phone: string,dob:string): Promise<void> => {
const endpoint = "/user/updateUserDetails"
 
    const requestData = {
  first_name:  name,
  last_name: null,
  user_name:  name.toLowerCase(),
  email_id:  null,
  dob: null,
  phone_number:  phone,
  gender: null
  
  };

  try {
    const res = await axios.post(`${API_ENDPOINT}${endpoint}`, requestData, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET, POST, OPTIONS"
      }
    });

    if (res.status === 200) {
     
    }
  } catch (error) {
    console.error('Otp verification Failed:', error);
    
  }
};





 
// const handleVerifyOtp = (e) => {
// e.preventDefault();
// if (formData.otp.length !== 6) {
//   setShowOtpError(true);
//   return;
// }
// setShowOtpError(false);
// setShowLoading(true);


// const endpoint = isSignup
//   ? "/auth/verifyNumberSignUp"
//   : "/auth/verifyNumberSignIn";
// axios
//   .post(
//     `${API_ENDPOINT}${endpoint}`,
//     {
//       phoneNumber: formData.phone,
//       otp: formData.otp,
//       is_partner: false
//     },
//     {
//       headers: {
//         "Content-Type": "application/json",
//         accept: "application/json",
//         "Access-Control-Allow-Origin": "*",
//         "Access-Control-Allow-Headers": "*",
//         "Access-Control-Allow-Methods": "GET, POST, OPTIONS"
//       }
//     }
//   )
//   .then((res) => {
//     setShowOtp(true);
//     setShowOtpButton(true);
//     setShowLoading(false);
//     if (res.status === 200) {
//       toast.success(
//         isSignup
//           ? "Sign up successful! Welcome aboard!"
//           : "Sign in successful! Welcome aboard!"
//       );
//       setTimeout(() => {
//         // login();
//         setJwtToken(JSON.stringify(`Bearer ${res.data.accessToken}`));
//         handleTokenVal(`Bearer ${res.data.accessToken}`);
//         sessionStorage.setItem(
//           "JWT_ACCESS_TOKEN",
//           JSON.stringify(`Bearer ${res.data.accessToken}`)
//         );
//         sessionStorage.setItem("JWT_REFRESH_TOKEN", res.data.refreshToken);
//         localStorage.setItem("user_details", JSON.stringify(formData));
//         sessionStorage.setItem("_user_logged_", true);
//         setUpdateBasicDetails(true);
//       }, 2000);
//     }
//   })
//   .catch((error) => {
//     setShowLoading(false);
//     toast.error(error.response.data.error);
//     // if (error.response && error.response.status === 404) {
//     //     toast.error("Otp not found");
//     // } else if (
//     //     error.response &&
//     //     error.response.data &&
//     //     error.response.data.message
//     // ) {
//     //     toast.error(error.response.data.message);
//     // } else {
//     //     toast.error("Sign up failed. Please try again later.");
//     // }
//   });
// };

// useEffect(() => {
// const userDetails = {
//   first_name: formData.name,
//   last_name: null,
//   user_name: formData.name.toLowerCase(),
//   email_id: formData.email,
//   dob: null,
//   phone_number: formData.phone,
//   gender: null
// };

 
//   axios
//     .post(`${API_E}/user/updateUserDetails`, userDetails, {
//       headers: {
//         "Content-Type": "application/json",
//         accept: "application/json",
//         "Access-Control-Allow-Origin": "*",
//         "Access-Control-Allow-Headers": "*",
//         "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
//         Authorization: jwtTokenObject
//       }
//     })
//     .then((res) => {
//     })
//     .catch((error) => {});
// }, [updateBasicDetails, formData, API_ENDPOINT, jwtTokenObject, isSignup]);


 