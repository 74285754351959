import React from "react";
import { getBrandColor } from "../../lib/brandService";
import { LuDot } from "react-icons/lu";

export const ServiceSummary: React.FC = () => {
  const brandColor = getBrandColor();

  const services = [
    {
      title: "Services",
      services: ["Facial", "HairCut", "Beard"],
    },
    {
      title: "Packages",
      services: ["Facial Package"],
    },
  ];
  return (
    <div className="hidden md:block bg-DARKEST_GREY_L4 rounded-md md:p-6 md:w-72 lg:w-96  lg:max-w-[40%] md:mt-6">
      <h3
        className=""
        style={{
          color: brandColor,
        }}
      >
        BOOKING SUMMARY
      </h3>

      <h3 className="text-DARKEST_GREY_L2 md:py-4">
        BOOKING ID:{" "}
        <span className="font-semibold pl-1 text-texthero ">#22111</span>
      </h3>
      <div className="">
        {services.map((item, key) => (
          <div className="">
            <h2 className="">{item.title}</h2>
            <div className="flex items-center">
              {item.services.map((service, key) => (
                <div
                  key={key}
                  className="flex items-center  py-2 text-DARKEST_GREY_L3 font-medium "
                >
                  <LuDot className="text-xl" />
                  <p className="">{service}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <hr className="text-DARKEST_GREY_L3" />
      <div className="flex items-center text-DARKEST_GREY_L2 py-3">
        <h3 className="">Tuesday,20-08-24</h3>
        <div className="flex flex-1" />
        <p className="">9:30PM</p>
      </div>
      <div className=""></div>
      <hr className="text-DARKEST_GREY_L3" />
      <div className="text-DARKEST_GREY_L2 py-3 ">
        <h4 className="">Preetham</h4>
        <p className="text-DARKEST_GREY_L1">Lucas Salon Hyderabad</p>
      </div>
    </div>
  );
};
