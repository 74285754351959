import { createSlice,PayloadAction } from "@reduxjs/toolkit";

interface UserCartState {
    addedServices:Array<{
        addedService:string,
        price: number,
        discountAmount:number,
        time:string,
        serviceType?:string,
        
        
        sub_category_id:string

    }>;
    addUpPrice:number;
    discount:number;
    total:number;
    totalDiscount:number,
    totalService:number;
    totaltime:string,
    cartAdd?:boolean,
    offerMargin?:number

}

const initialState:UserCartState = {
    addedServices: [],
    addUpPrice: 0,
    discount: 0,
    total: 0,
    totalDiscount:0,
    
    totalService: 0,
    totaltime: '',
    cartAdd:false

}

const userCartSlice = createSlice({
    name: 'userCart',
    initialState: initialState,
    reducers: { 
        addService(state, action: PayloadAction<{ addedService: string, price: number, time
            : string, serviceType: string,sub_category_id:string,discountAmount:number }>) {
                state.addedServices.push(action.payload);
                state.addUpPrice += action.payload.price;
               
            
                state.total = state.addUpPrice - state.discount;
                state.totalDiscount=  state.totalDiscount+action.payload.discountAmount
                state.totalService = state.totalService + 1;
                state.totaltime = +state.totaltime + +action.payload.time + ' ';
                },
                removeService(state, action: PayloadAction<{ addedService: string, price: number, time: string, serviceType: string,  sub_category_id: string,discountAmount:number  }>) {
                    state.addedServices = state.addedServices.filter(service => service.sub_category_id !== action.payload.sub_category_id);
                    state.addUpPrice -= action.payload.price;
                    state.totalService -= 1;
                    state.totalDiscount=  state.totalDiscount-action.payload.discountAmount
                    state.totaltime = +state.totaltime - +action.payload.time + ' ';
                    state.total = state.addUpPrice - state.discount;
                  },
                      
                        setCardAdd(state,action:PayloadAction<boolean>) {
                            state.cartAdd = action.payload;
                        },
                    }
})

export const {addService,removeService,setCardAdd} =userCartSlice.actions;
export default userCartSlice.reducer;
