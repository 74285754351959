
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IBookingState {
  bookingState:string,
  isBookingFlow:boolean,
  selectedProfessionId?:number,
  selectedDate:string,
  selectedTime:string[],
}

const initialState: IBookingState = {
  bookingState:'',
  isBookingFlow:false,
  selectedProfessionId:0,
  selectedDate:'',
  selectedTime:[]

   
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setBookingState(state, action: PayloadAction<string>) {
      state.bookingState = action.payload;
    },
    setBookingFlow (state,action:PayloadAction<boolean>) {
      state.isBookingFlow = action.payload
    },
    setProfessionId(state,action:PayloadAction<number>) {
      state.selectedProfessionId=action.payload
    },
    setDateState(state,action:PayloadAction<string>) {
      state.selectedDate=action.payload
    },
    setTimeState(state,action:PayloadAction<string[]>) {
      state.selectedTime=action.payload
    }
   
  },
});

export const { setBookingState ,setBookingFlow,setProfessionId,setDateState,setTimeState} = bookingSlice.actions;
export default bookingSlice.reducer;
