import React, { useState, useEffect } from 'react';
import SvgCard from '../Svg/SvgCard';

const SalonStatus: React.FC = () => {
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    const updateSalonStatus = () => {
 

      const now = new Date();
      const currentTime = now.getHours() + now.getMinutes() / 60;

      const openingTime = 10;
      const closingTime = 21;

      const isOpen = currentTime >= openingTime && currentTime < closingTime;
      const timeLeft = isOpen ? closingTime - currentTime : openingTime - currentTime + (currentTime >= closingTime ? 24 : 0);

      const hoursLeft = Math.floor(timeLeft);
      const minutesLeft = Math.round((timeLeft - hoursLeft) * 60);

      const hourLabel = hoursLeft === 1 ? 'hr' : 'hrs';
      const minuteLabel = minutesLeft === 1 ? 'min' : 'mins';

// Opens in 7hrs 30 mins
      setMessage(
        isOpen
          ? `Closes in ${hoursLeft} ${hourLabel}  ${minutesLeft} ${minuteLabel}.`
          : `Opens in  ${hourLabel}  ${minutesLeft} ${minuteLabel}.`
      );
    };

    updateSalonStatus();
    const intervalId = setInterval(updateSalonStatus, 60000);

    return () => clearInterval(intervalId);
  }, []);

  return <div className=''>

<p className="w-max text-xs md:text-[13px] lg:text-sm text-blue-2 px-6 md:px-0  lg:px-3 py-1 absolute top-3  md:left-6 lg:left-2.5   ">{message}</p>
<SvgCard
    width={197}
    height={56}
    viewBox="0 0 197 56"
    pathData="M177 42C181.418 42 185 38.4183 185 34L185 18C185 13.5817 181.418 9.99999 177 9.99999L19.0342 10C17.8405 10 16.8729 10.9676 16.8729 12.1613V12.1613C16.8729 13.2514 16.0611 14.1708 14.9794 14.3059L13.1876 14.5297C12.5738 14.6063 12.5742 15.496 13.1881 15.5722V15.5722C15.2927 15.8333 16.8729 17.6216 16.8729 19.7424L16.8729 34C16.8729 38.4183 20.4546 42 24.8729 42L177 42Z"
    filterId="filter0_d_6961_46417"
  />
  </div>;
};

export default SalonStatus;
