
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ICustomState {
  
  isPopOpen:boolean,
  openCustServicePop:boolean,
  openSharePop:boolean,
  openCustDetailsPop:boolean,
  openAllDrops:boolean,
  serviceRating:string|number,
  greetMsg:string,
  showSpin:boolean
}

const initialState: ICustomState = {
    isPopOpen:true,
    greetMsg :'',
    openSharePop:false,
    openCustServicePop:false,
    openCustDetailsPop:false,
    openAllDrops:false,
    showSpin:false,
    serviceRating:''
   
};

const customSlice = createSlice({
  name: 'custom',
  initialState,
  reducers: {
    setPopOpenState(state, action: PayloadAction<boolean>) {
      state.isPopOpen = action.payload;
    },
    setGreetMsgState(state,action:PayloadAction<string>){
      state.greetMsg = action.payload;
    },
    setOpenSharePopState(state,action:PayloadAction<boolean>) {
      state.openSharePop = action.payload;
    },
    setOpenCustServieState(state,action:PayloadAction<boolean>) {
      state.openCustServicePop = action.payload;
    },
    setOpenCustDetailsState(state,action:PayloadAction<boolean>) {
      state.openCustDetailsPop = action.payload;
    },
    setOpenAllDropsState(state,action:PayloadAction<boolean>) {
      state.openAllDrops = action.payload;
      },
      setServiceRatingState(state,action:PayloadAction<string|number>) {
        state.serviceRating= action.payload
      },
      setShowSpinState(state,action:PayloadAction<boolean>){
        state.showSpin = action.payload
      }
   
  },
});

export const { setPopOpenState,setGreetMsgState,setOpenSharePopState,setOpenCustServieState,setOpenCustDetailsState,setOpenAllDropsState,setServiceRatingState,setShowSpinState } = customSlice.actions;
export default customSlice.reducer;
