import React ,{useState} from 'react';
import { IoIosStar } from "react-icons/io";
import { useDispatch } from 'react-redux';
import { setServiceRatingState } from '../../store/slices/customSlice';

export const ReveiwFeedback :React.FC = ()=>{
  const dispatch = useDispatch();

    const [rating, setRating] = useState(0);
    const [blink, setBlink] = useState(false);
  
    const handleRatingChange = (newRating: number) => {
      setRating(newRating);
      dispatch(setServiceRatingState(newRating))
      setBlink(true);
    };
  
    return (
      <section className="pt-3 md:pt-0 space-y-2 md:space-y-4">
  
        <h2 className="text-center md:text-start ">
          Please Share your Experience 
        </h2>
        <div className="flex items-center justify-center md:justify-start text-primary gap-2 md:gap-4 text-2xl md:text-4xl lg:text-5xl">
          {Array(5).fill(0).map((_, i) => (
            <IoIosStar 
              key={i} 
              className={`text-${i < rating ? '#e7b536' : 'DARKEST_GREY_L4'} ${blink && i < rating ? 'animate-blink' : ''}`} 
              onClick={() => handleRatingChange(i + 1)} 
            />
          ))}
        </div>
        <div className="text-center md:text-start md:text-3xl lg:text-4xl font-light text-DARKEST_GREY_L2">

        {rating === 1 && <p>1 - Very Poor</p>}
        {rating === 2 && <p>2 - Poor</p>}
        {rating === 3 && <p>3 - Good</p>}
        {rating === 4 && <p>4 - Very Good</p>}
        {rating === 5 && <p>5 - Excellent</p>}
        </div>
      </section>
    )
}