// store/slices/packagesSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExtPackage, Package } from '../../types/salonServicesTypes';
import { Professional } from '../../types/bookingFlowTypes';


interface ProfessionalState {
  loading: boolean;
  error: string | null;
  professionals: Professional[] | null;
}

const initialState: ProfessionalState = {
  loading: false,
  error: null,
  professionals: null,
};

const professionalSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    fetchProfessionalStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchProfessionalSuccess(state, action: PayloadAction<Professional[]>) {
      state.loading = false;
      state.professionals = action.payload;
    },
    fetchProfessionalFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
    fetchProfessionalStart,
    fetchProfessionalSuccess,
    fetchProfessionalFailure,
} = professionalSlice.actions;

export default professionalSlice.reducer;
