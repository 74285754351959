import React,{FormEvent, useState} from 'react';
import { LuDot } from "react-icons/lu";
import { Link } from 'react-router-dom';
import Cart from './Cart';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { formatTime } from '../../utils/validations';
import { setPopOpenState } from '../../store/slices/customSlice';
import { IoClose } from 'react-icons/io5';

interface ICartProps {
    summary: {
      title: string,
      ctaName: string,
      onclick: () => void
    }
  }
  

const MobileCart :React.FC<ICartProps> = ({summary})=> {
    const dispatch = useDispatch();
    const [showDetails, setShowDetails] = useState<boolean>(false)
    const cartValue = useSelector((state: RootState) => state.userCart);

    const handlePop = (e:FormEvent) =>{
        e.preventDefault()
        setShowDetails(!showDetails)
        dispatch(setPopOpenState(true))
    }
    return (
        <div className=" z-50 w-full gradBorder h- relative bg-white flex items-center p-4 rounded-xl">
      

        <div className={`${!showDetails&&'hidden'} fixed top-0 left-0 h-full flex flex-col justify-center items-center f  bottom- 80 bg-opacity-50 backdrop-filter backdrop-blur-md   bg-[#29292b81] bg-blend-saturation px-7 `}>
            <IoClose className='text-2xl bg-white w-7 h-7  p-1 mb-2 rounded-full' onClick={handlePop}/>
          <Cart   summary={summary} />
        </div>
                 <div className="">
              <h2 className="text-blue font-semibold">
              ₹{cartValue.total}
              </h2>
              <div className="text-blue flex  items-center text-xs">
                  <h4 className="">
                 {cartValue.totalService} service 
                  </h4>
                  <LuDot/>
                  <h4 className="">
                {formatTime(+cartValue.totaltime)}
                  </h4>
              </div>
              <button className="text-xs text-DARKEST_GREY_L1" onClick={handlePop}>
              View Details
              </button>
          </div>
          <div className="flex flex-1"/> 
          {/* <Link to='/lucas/hyderabad/orderDetails'> */}
          <button className="bg-hero text-white px-7 py-2 font-medium rounded-md text-xs"  onClick={summary.onclick}>
          Continue
          </button>
          {/* </Link> */}
      </div>
    )
}

export default MobileCart;