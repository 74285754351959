import React from 'react';
import PopUpLayout from '../Layout/PopUpLayout';
import logo from '../../assets/custom/favicon.svg';

export const Loader: React.FC = () => {
  return (
    <PopUpLayout>
      <div className="relative">
        <img src={logo} alt="Logo" className="w-12 h-12 md:w-20 md:h-20" />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="loader-circle w-12 h-12 md:w-20 md:h-20"></div>
        </div>
      </div>
    </PopUpLayout>
  );
};