import React, { useState, useEffect } from 'react';
import { getSalonDiscount } from '../../lib/salonProfileService';
import { ISalonDiscountProps } from '../../types/salonProfileTypes';
import { SalonOffers } from '../../types/salonDetailsTypes';

interface ISalonDiscountCardProps {
  offers:SalonOffers[]
}


const SalonDiscountCard: React.FunctionComponent<ISalonDiscountCardProps> = ({offers}) => {
 
  return (
    <section className=" overflow-auto hideScrollBar flex items-center gap-2 md:gap-3">
      {
  offers.flat().map((offer, index) => (
    <div className=" px-4 py-1.5 md:py-2 text-xs md:text-sm  gradBorder rounded-md bg-[#FFF8E7]" key={index} >
      <p className="font-semibold">{offer.offer_percentage}% off</p>
      <p className="w-max">above ₹{offer.cart_value}</p>
    </div>
  ))
}
    </section>
  );
};

export default SalonDiscountCard;