// import { Brand } from '../types/salonProfileTypes'
import { ISalonDetailsProps, ISalonDiscountProps, ISalonTimingsProps } from '../types/salonProfileTypes'

 
import { initializeSalonInfo, initializeSalonOffers } from '../data/salonDetailsData'
import { SalonOffers } from '../types/salonDetailsTypes'

//initial Variable Values
let fetchedImgs: Array<string> = []
let fetchedSalonInfo: ISalonDetailsProps
let fetchedDiscounts: SalonOffers[] =[]
let fetchedSalonTimings: ISalonTimingsProps[] = []

 
async function fetchSalonGallary(brandName: string): Promise<void> {
    let salonInfo = await initializeSalonInfo();
    
    fetchedImgs = salonInfo.salonImgs;

}


async function fetchSalonDetails(brandName: string): Promise<void> {
    let salonInfo = await initializeSalonInfo();
    fetchedSalonInfo = salonInfo;
}

async function fetchSalonDiscounts(brandName: string): Promise<void> {
    let salonDiscouts = await initializeSalonOffers();
    fetchedDiscounts = salonDiscouts
}

async function fetchSalonTimings(brandName: string): Promise<void> {

    const salonTimings: ISalonTimingsProps[] = [
        {
            day: 'Monday',
            timings: '10:00am - 09:00pm'
        },
        {
            day: 'Tuesday',
            timings: '10:00am - 09:00pm'
        },
        {
            day: 'Wednesday',
            timings: '10:00am - 09:00pm'
        },
        {
            day: 'Thursday',
            timings: '10:00am - 09:00pm'
        },
        {
            day: 'Friday',
            timings: '10:00am - 09:00pm'
        },
        {
            day: 'Saturday',
            timings: '10:00am - 09:00pm',
            closed: true
        },
        {
            day: 'Sunday',
            timings: '10:00am - 09:00pm',
        },
    ]
    fetchedSalonTimings = salonTimings;

}

// Invoking The Function
fetchSalonGallary('lucas')
fetchSalonDetails('lucas')
fetchSalonDiscounts('lucas')
fetchSalonTimings('lucas')

//export the fetched Result

function getSalonGallary(): Array<string> {
    return fetchedImgs;
}
function getSalonDetails() {
    return fetchedSalonInfo;

}
function getSalonDiscount() {
    return fetchedDiscounts;
}

function getSalonTimings() {
    return fetchedSalonTimings
}



export { getSalonGallary, getSalonDetails, getSalonDiscount, getSalonTimings }