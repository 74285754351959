import React from 'react';
import NavBar from '../components/Layout/NavBar';
import ServiceReviewMain from '../components/Review/_ServiceReviewMain';
import Footer from '../components/Layout/Footer';


const ServiceReview :React.FC = ()=>{
    return (
       <main className="">
        <NavBar/>
        <section className="pb-14 lg:pb-20">
            <ServiceReviewMain/>
        </section>

        <Footer/>
       </main>
    )
}

export default ServiceReview;