// lib/shopDetailsApi.ts
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { AppDispatch } from '../store/store';
import { fetchReviewsStart, fetchReviewsSuccess, fetchReviewsFailure } from '../store/slices/salonReviewSlice';
import { APOLLO_CLIENT_URI } from '../config/config';
 
const client = new ApolloClient({
  uri:`${APOLLO_CLIENT_URI}/graphql`,
  cache: new InMemoryCache(),
});

const GET_SHOP_REVIEWS_QUERY = gql`
 query GetShopReviews($shopId: ID!, $rating: [Int]) {
  getShopReviews(shop_id: $shopId, rating: $rating) {
    ratings {
      rating
      review
      services
      user_name
      created_at
    }
    averageRating
    totalRatings
    ratingCount {
      rating5
      rating4
      rating3
      rating2
      rating1
    }
  }
}
`;


 
export const fetchShopReviews = async (
    dispatch: AppDispatch,
    shopId: string,
    rating:number[]
  ): Promise<void> => {
    dispatch(fetchReviewsStart());
  
    try {
      const { data } = await client.query({
        query: GET_SHOP_REVIEWS_QUERY,
        variables: { shopId,rating },
      });
  
      dispatch(fetchReviewsSuccess(data.getShopReviews));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchReviewsFailure(error.message));
      } else {
        dispatch(fetchReviewsFailure("An unknown error occurred"));
      }
    }
  };