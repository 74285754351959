import React, { useEffect } from "react";
import SelectProfessional from "./SelectProfessional";
import TimeSchedule from "./TimeSchedule";
import SelectPayment from "./SelectPayment";
import OrderNotes from "./OrderNotes";
import CustomerDetails from "./CustomerDetails";
import store, { AppDispatch, RootState } from "../../store/store";
import { fetchShopProfessionals } from "../../service/salonProfessionalService";
import { useSelector } from "react-redux";
import CustDetailsPopUpCard from "../Ui/CustDetailsPopUpCard";
import { fetchUserAllCustomers } from "../../service/userCustomerDetailsService";
import { extractShopIdFromUrl } from "../../utils/salonProfile";

const OrderDetailsMain: React.FC = () => {
  const salonId = extractShopIdFromUrl();


  useEffect(() => {
    const dispatch: AppDispatch = store.dispatch;

    fetchShopProfessionals(dispatch, salonId);
    fetchUserAllCustomers(dispatch, salonId);
  }, []);

  const salonProfessionals = useSelector(
    (state: RootState) => state.salonProfessionals.professionals
  );
  const userAllCustomer = useSelector((state:RootState)=>state.userAllCustomerDetails.customers)
  const openCustDetailPop = useSelector(
    (state: RootState) => state.custom.openCustDetailsPop
  );
  


  return (
    <main className="relative">
      <div
        className={`${
          openCustDetailPop
            ? "fixed left-0 top-0 z-50 w-screen h-screen"
            : "hidden"
        }  `}
      >
        <CustDetailsPopUpCard custDetails={userAllCustomer
} />
      </div>
      <div className=" pb-8  w-full hideScrollBar overflow-scroll">
        <SelectProfessional salonProfessionalsData={salonProfessionals ?? []} />
      </div>
      <TimeSchedule />
      <div className=" py-12 lg:py-8">
        <SelectPayment />
      </div>
      {/* <OrderNotes /> */}
      <div className="">
        <CustomerDetails />
      </div>
    </main>
  );
};

export default OrderDetailsMain;
