import React, { useState } from "react";
import PopUpLayout from "../Layout/PopUpLayout";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenCustServieState,
  setPopOpenState,
} from "../../store/slices/customSlice";
import { CustServicePop } from "../../types/salonServicesTypes";
import { RootState } from "../../store/store";
import { fetchServiceType } from "../../store/slices/salonServicesSlice";

interface ICustSevicePopProps {}

const CustServicePopUpCard: React.FC<ICustSevicePopProps> = () => {
  const dispatch = useDispatch();

  const [selectedService, setSelectedService] = useState<number>();

  const customServ = useSelector(
    (state: RootState) => state.salonServices.custServices
  );
  const custService = [customServ];

  return (
    <section className="z-50 relative ">
      {custService.map((service, index) => (
        <PopUpLayout>
          <RxCross2
            className="right-0 float-right text-xl m-3 cursor-pointer"
            onClick={() => {
              dispatch(setPopOpenState(false));
              dispatch(setOpenCustServieState(false));
            }}
          />
          <div className="p-5 md:p-7 lg:p-8 bg-white rounded-md ">
            <h2 className="font-medium md:text-xl xl:text-[22px] w-max">
              {service?.category_name} Service
            </h2>
            <h4 className="font-medium text-sm pt-1">Customize Your Service</h4>
            <div className="mt-5 md:mt-10">
              {service?.customizations?.map((item, index) => (
              <>
                <div
                  className={`flex gap-10  lg:gap-20 ${
                    selectedService == index && "bg-DARKEST_GREY_L4"
                  } pb-2 px-4`}
                  key={index}
                  onClick={() => {
                    dispatch(fetchServiceType(item.sub_customization_name));

                    if (index == selectedService) {
                      setSelectedService(-1);
                    } else {
                      setSelectedService(index);
                    }

                    setTimeout(() => {
                      dispatch(setPopOpenState(false));
                      dispatch(setOpenCustServieState(false));
                    }, 1000);
                  }}
                >
                  <div className="w-32">
                    <h4 className="font-medium text-sm md:text-base">
                      {item?.sub_customization_name}
                    </h4>
                    <p className="text-DARKEST_GREY_L3 text-xs pt-1">
                      {item?.alloted_time}
                    </p>
                  </div>
                  <div className="flex gap-1 text-sm md:text-base ">
                    <h3 className="">{item?.service_cost}</h3>
                    <h4 className="text-DARKEST_GREY_L3 line-through">
                      ₹ 2200
                    </h4>
                  </div>
                </div>

              </>
            ))}
            </div>
          </div>
        </PopUpLayout>
      ))}
    </section>
  );
};

export default CustServicePopUpCard;
