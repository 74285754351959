import React from 'react';
import { FaMinus, FaPlus } from "react-icons/fa";
import RadioPP from '../Common/RadioPP';
import OrderHeaderBar from '../Layout/OrderHeaderBar';

const SelectPayment :React.FC =()=>{

    const handleSelectionChange = (value: string) => {
    };

    return (
        <section className="">
            <OrderHeaderBar title='Select Professional' />
   
      <div className="px-3 lg:px-10 lg: pt-5">

      <RadioPP
                options={['Pay at the Salon', 'Pay Now Online'  ]}
                name="payment method"
                onChange={handleSelectionChange}
                />
                </div>
        </section>
    )
}

export default SelectPayment;