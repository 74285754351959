import React, { useState, useEffect } from "react";
import { Slots } from "../../types/bookingFlowTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setTimeState } from "../../store/slices/bookingSlice";

interface ISlotTimeProps {
  slots: Slots[];
}

const SlotTimeCard: React.FC<ISlotTimeProps> = ({ slots }) => {
  const dispatch = useDispatch();
  const [selectedTime, setSelectedTime] = useState<string | number>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const slotsTimings: string[] = useSelector((state: RootState) => state.booking.selectedTime);

  const handleClick = (selectedSlotIndex: number, slotArray: Slots[]) => {
    const numberOfSlotsToBlock = 4;
    // handleOrderStep(3); // uncomment if needed
    setSelectedTime(""); // clear selected time
    setErrorMessage(""); // clear error message
    setShowPopup(false); // hide popup

    // Check if the selected slot is available
    if (!slotArray[selectedSlotIndex].slot_available) {
      setErrorMessage("Selected slot is not available. Please choose another slot.");
      setShowPopup(true); // show popup
      return;
    }

    // Check if all slots to be blocked are available
    const endIndex = selectedSlotIndex + numberOfSlotsToBlock;
    if (endIndex > slotArray.length) {
      setErrorMessage(`As per the services added, you need to book continuous ${numberOfSlotsToBlock} slots.`);
      setShowPopup(true); // show popup
      return;
    }
    for (let i = selectedSlotIndex; i < endIndex; i++) {
      if (!slotArray[i].slot_available) {
        setErrorMessage(`As per the services added, you need to book continuous ${numberOfSlotsToBlock} slots.`);
        setShowPopup(true); // show popup
        return;
      }
    }

    // Block the selected slots
    const newBlockedSlots = slotArray.slice(selectedSlotIndex, endIndex);
    const newSelectedSlots = newBlockedSlots.map((slot) => slot.slot_time);
    dispatch(setTimeState(newSelectedSlots));
    console.log('added time', newSelectedSlots);

    // Update order values
    let orderValues: any = sessionStorage.getItem('__order_values__');
    orderValues = orderValues ? JSON.parse(orderValues) : {};
    orderValues.slot_timings = newSelectedSlots;
    const updatedOrderValues = JSON.stringify(orderValues);
    sessionStorage.setItem('__order_values__', updatedOrderValues);
  };

  const handleSlotTime = (item: Slots, slots: Slots[]) => {
    const currentIndex = slots.findIndex((slot) => slot.slot_time === item.slot_time);
    if (currentIndex !== -1) {
      const nextFourTimings = slots.slice(currentIndex + 1, currentIndex + 4).map((slot) => slot.slot_time);
      const newSlotsTimings = [...slotsTimings, item.slot_time, ...nextFourTimings];
      dispatch(setTimeState(newSlotsTimings)); // update the state with the new array
      console.log('added time', newSlotsTimings); // log the updated array
    }
  };

  return (
    <>
      {slots.map((item, key) => (
        <div
          key={key}
          className={`  ${slotsTimings.includes(item.slot_time) ? 'bg-DARKEST_GREY_L4' : '  '} w-max border rounded-2xl px-3 lg:px-6 py-1 mt-6 flex flex-col justify-center`}
          onClick={() => {
            setSelectedTime(item.slot_id);
            handleClick(slots.indexOf(item), slots);
          }}
        >
          <div className="flex items-center gap-1 pt-1 ">
            <h3 className="text-texthero font-medium"> {item.slot_time}</h3>
          </div>
        </div>
      ))}
    </>
  );
};

export default SlotTimeCard;