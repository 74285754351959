// store/slices/reviewsSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Rating, RatingCount, ReviewsState } from '../../types/salonServicesTypes';




const initialState: ReviewsState = {
  loading: false,
  error: null,
  ratings: null,
  averageRating: null,
  totalRatings: null,
  ratingCount: null,
  checkedTypes:0
};

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    fetchReviewsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchReviewsSuccess(state, action: PayloadAction<ReviewsState>) {
      state.loading = false;
      state.ratings = action.payload.ratings;
      state.averageRating = action.payload.averageRating;
      state.totalRatings = action.payload.totalRatings;
      state.ratingCount = action.payload.ratingCount;
    },
    fetchReviewsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchReviewsStart,
  fetchReviewsSuccess,
  fetchReviewsFailure,
} = reviewsSlice.actions;

export default reviewsSlice.reducer;
