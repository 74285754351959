import React, { useState, useRef, FormEvent } from 'react';
import { getSalonCategories } from '../../lib/salonServicesService';
import { getBrandColor } from '../../lib/brandService';
import { FaChevronLeft,FaChevronRight  } from "react-icons/fa6";
import Carousel from '../Carousel/Carousel';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface ISalonCategoriesProps {}

const SalonCategories: React.FunctionComponent<ISalonCategoriesProps> = (props) => {
    const [activeCatlog, setActiveCatlog] = useState<string>('Recommended');
    const brandColor = getBrandColor();

    // fetching  Subcategoris 
    const {subCategories} = useSelector((state:RootState)=>state.salonServices)
    const salonCatelogs = subCategories?.map(category=>category.category_name)??[]
 
    const handleSuggestionClick = (e:FormEvent, id: string) => {
        e.preventDefault();
        const sectionElement = document.getElementById(id);
        if (sectionElement) {
          const offset = sectionElement.offsetTop - (window.innerHeight / 2);
          window.scrollTo({ top: offset, behavior: 'smooth' });
        }
      };

      let isPopActive = useSelector(
        (state: RootState) => state.custom.isPopOpen
      );
    return (
        
       <Carousel>
            {salonCatelogs.map((item, key) => (
                    <div 
                        className={`${isPopActive ? "-z-50" : "z-auto"} px-5 lg:px-7 py-1.5 lg:py-2 rounded-full`} 
                        key={key} 
                        style={{
                            backgroundColor: activeCatlog === item ? brandColor : '#EBEBEB'
                        }}
                        onClick={(e) =>{setActiveCatlog(item)

                            handleSuggestionClick(e,item.toLowerCase())

                        }
                        }
                    >
                        <h2 className={`${activeCatlog === item ? 'text-[#FEFFFF]' : 'text-DARKEST_GREY_L1'} w-max text-xs lg:text-base  font-medium`}>
                            {item}
                        </h2>
                    </div>
                ))}
       </Carousel>
    );
};

export default SalonCategories;
