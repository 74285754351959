import React from "react";
import { FaChevronUp } from "react-icons/fa";
import { getBrandColor } from "../../lib/brandService";
// import { ExtractUserGender } from "../../utils/userProfile";

interface ISalonFeaturesProps {
  onIconClick: (sectionId: "services" | "packages"   | "reviews" | "about") => void;
  activeSection: "services" | "packages" | "offers" | "reviews" | "about";
}

const SalonFeatures: React.FunctionComponent<ISalonFeaturesProps> = ({ onIconClick, activeSection }) => {
  const featureOptions: Array<"services" | "packages"  | "reviews" | "about"> = [
    "services",
    "packages",
    
    "reviews",
    "about",
  ];
  const brandColor = getBrandColor();


// function  to scroll to top 
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="overflow-x-auto w-full relative h-10 hideScrollBar lg:overflow-hidden">
      <div className="w-full flex items-center space-x-6 md:space-x-0 md:justify-around">
        <FaChevronUp className="text-DARKEST_GREY_L2 text-sm md:text-base absolute md:relative left-0 cursor-pointer"   onClick={handleScrollToTop} />
        {featureOptions.map((item) => (
          <div
            className="relative flex flex-col justify-center items-center cursor-pointer"
            key={item}
            onClick={() => onIconClick(item)}
          >
            <p
              className={`${
                activeSection === item ? "font-semibold" : "text-DARKEST_GREY_L2"
              } text-xs md:text-sm lg:text-base`}
              style={{
                color: activeSection === item ? brandColor : "",
              }}
            >
              {item.toUpperCase()}
            </p>
            <hr
              className={`absolute top-7 w-20 lg:w-28 -z-50 ${
                activeSection === item ? "" : "hidden"
              } lg:border-[1.4px]`}
              style={{
                color: brandColor,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SalonFeatures;
