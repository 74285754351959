import  React , {useState,useEffect} from 'react';
import { getBrandColor } from '../../lib/brandService';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';


interface IButtonPpProps {
    buttonName:Array<string>|string,
    custClassName?:string,
    custLabel?:string,
    onClickFun?():void,
    labelFun?():void,
    isSpecial?:boolean,
    clsLabel?:string,
    service_id?:string,
    isAdded?:boolean
    
    
}

const ButtonPP: React.FunctionComponent<IButtonPpProps> = (props) => {

    const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false)
    const {buttonName,custClassName='',custLabel,isSpecial=false,onClickFun,labelFun,clsLabel,service_id,isAdded} = props;
    const brandColor = getBrandColor();
const cartValue = useSelector((state:RootState)=>state.userCart.addedServices)


    useEffect(()=>{
        const existingService = cartValue.find(service => service.sub_category_id === service_id);
        if(existingService) {
            setIsButtonClicked(true);
        }  else {
            setIsButtonClicked(false);
        }
    },[cartValue, service_id])

    //handler for Button click 
    const handleClick = ()=> {
        setIsButtonClicked(!isButtonClicked);
        if(onClickFun) {
            onClickFun()
        }
    }

  
    
  return (
    <div className=" ">
        <button className={`${custClassName} text-white `} 
        style={{
            backgroundColor: isButtonClicked ?brandColor:'#020202',
        }}
        onClick={handleClick}
        >
        {isSpecial?buttonName: isButtonClicked ?buttonName[1]:buttonName[0]}
        </button>
        {/* <p className={`${clsLabel} cursor-pointer text-xs md:text-sm text-DARKEST_GREY_L1 lg:pt-1 text-center`} onClick={handlePop}>
            {isButtonClicked?'Remove':custLabel}
        </p> */}
         
    </div>
  ) ;
};

export default ButtonPP;
