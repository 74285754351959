import React, { useState, useEffect } from "react";
import { getSalonTimings } from "../../lib/salonProfileService";
import { ISalonTimingsProps } from "../../types/salonProfileTypes";
import SvgCard from "../Svg/SvgCard";
import SvgCardMob from "../../assets/brand/openingSvg.png";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface IRectangleCardProps {
  cusWidth?: string;
  cusHeight?: string;
}

const RectangleCard: React.FunctionComponent<IRectangleCardProps> = (
  props: IRectangleCardProps
) => {
  return (
    <div className="  z-40  ">
      <div className="md:hidden relative  ">
        <div className="abso lute top-7 right-28">

        </div>
        <div className="absolute top-8 left-7">
        <CardDetails />             
          
        </div>
        <img src={SvgCardMob} alt="" className=" " />

      </div>

      <div className="hidden md:block">
        <div className="absolute left-12 top-6 z- ">
          <CardDetails />
        </div>

        <SvgCard
          width={330}
          height={284}
          viewBox="0 0 330 284"
          pathData="M309.5 270C313.918 270 317.5 266.418 317.5 262L317.5 18C317.5 13.5817 313.918 10 309.5 10L33.6658 10C29.2475 10 25.6658 13.5818 25.6658 18L25.6658 38.4742C25.6658 41.3169 24.1572 43.9461 21.703 45.3807L13.4818 50.1864C12.8202 50.5732 12.8216 51.5297 13.4843 51.9145L21.683 56.6754C24.1485 58.1071 25.6658 60.7426 25.6658 63.5936L25.6658 262C25.6658 266.418 29.2475 270 33.6658 270L309.5 270Z"
          filterId="filter0_d_5787_23484"
        />
      </div>
    </div>
  );
};

export default RectangleCard;

type ICardProp={
  isSpec?:boolean
}


export const CardDetails: React.FC<ICardProp> = ({isSpec}) => {
  const { data } = useSelector((state: RootState) => state.salonDetails);

  // Ensure that `shop_weekly_timings` is either an object or handle it if it's not
  const salonDetails: { [key: string]: string } = data?.shop_weekly_timings && !Array.isArray(data.shop_weekly_timings)
    ? (data.shop_weekly_timings as { [key: string]: string })
    : {};

  // Extracting Today's Day
  const today = new Date();
  const dayOfWeek: string = today.toLocaleDateString("en-US", {
    weekday: "long",
  });

  return (
    <div className="">
      <h2 className={` ${isSpec?'hidden':"text-blue-2 text-sm md:text-base pb-2"  } `}>Opening Hours</h2>
      <div className="">
        {Object.entries(salonDetails).map(([day, hours]) => (
          <div
            key={day}
            className={`flex items-center pt-2 gap-7 relative ${
              dayOfWeek.toLowerCase() == day ? "font-semibold" : "font-normal"
            } ${isSpec?'text-sm md:text-base text-DARKEST_GREY_L2 ':'text-blue-2 text-xs'}  md:text-sm w-full`}
          >
            <h3 className="w-20 capitalize">{day}</h3>
            <p className="w-max place-items-start ">{hours as string}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

