import React, { useState } from "react";
import SvgCard2 from "../Svg/SvgCard2";
import profImg from "../../assets/salon/prof1.jpg";
import { EllipseSvg } from "../Svg/EllipseSvg";
import { IoIosStar } from "react-icons/io";
import { ISalonProfessionals } from "../../types/salonOrderDetailsTypes";
import { Professional } from "../../types/bookingFlowTypes";
import { useDispatch } from "react-redux";
import { setProfessionId } from "../../store/slices/bookingSlice";

interface ISalonProfessionalProps {
  salonProfession: Professional;
  index: string | number;
}

const ProfessionalCard: React.FC<ISalonProfessionalProps> = ({
  salonProfession,
  index,
}) => {
  const dispatch = useDispatch();

  const [isProfSelected, setIsProfSelected] = useState<string | number>();

  return (
    <div
      className={`${
        isProfSelected == +index + 1 ? "bg- DARKEST_GREY_L4" : "bg -white"
      }  relative rounded-xl  shadow-sm w-40  lg:w-44 mt -5 lg:mt -10`}
      key={salonProfession.professional_id}
      onClick={() => {
        dispatch(setProfessionId(+salonProfession.professional_id));
        if (isProfSelected == salonProfession.professional_id) {
          setIsProfSelected(0);
        }

        setIsProfSelected(+index + 1);
      }}
    >
      {/* <SvgCard2 /> */}
      {/* <div className={`${salonProfession.price==''&&'hidden'} bg-DARKEST_GREY_L4 rounded-full lg:px-3 lg:py-1 w-max absolute top-3 right-2 `}>
        <p className="text-xs font-medium ">
          {salonProfession.price}
        </p>
      </div> */}
      <div className="relative flex flex-col justify-center items-center m-auto ">
        {/* <EllipseSvg /> */}
        <img
          // src={salonProfession?.profile_image_url}
          src={salonProfession.profile_image_url}
          alt="Profession"
          className="  rounded-full mb-4 w-32 h-32 lg:w-28 xl:w-32 lg:h-28 xl:h-32 lg:mt-2 object-cover drop-shadow-xl"
        />

        <div className="flex flex-col  justify-center items-center m-auto">
          <div className="w-max   h-max rounded-full lg: px-2 lg: py-0.5 absolute        bottom-2  bg-[#FEFFFF] border border-DARKEST_GREY_L3 flex items-center gap-1    m-auto">
            <p className="font-semibold text-texthero">4.3</p>
            <IoIosStar className="text-hero2 -pt-0.5" />
          </div>
        </div>
      </div>
      <div className="pt-1   lg:space-y- lg: pb-2">
        <h3 className="font-semibold text-texthero text-center capitalize">
          {salonProfession.user_name}
        </h3>
        <p className="text-DARKEST_GREY_L1 lg:text-sm text-center">
          {salonProfession.profession}
        </p>
      </div>
    </div>
  );
};

export default ProfessionalCard;
