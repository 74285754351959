import React from 'react';

interface IPopupLayout {
    children: React.ReactNode;

}

const PopUpLayout : React.FC<IPopupLayout> = ({children})=> {
    return (
        <div className="h-screen w-screen flexc-col z-50  inset-0 relative  flex items-center justify-center bg-opacity-50 backdrop-filter backdrop-blur-lg   bg-[#29292b81] bg-blend-saturation">
            <div className=" z-50 ">
                {children}
            </div>
        </div>
    )
}


export default PopUpLayout;