import React from "react";
import OrderHeaderBar from "../Layout/OrderHeaderBar";
import { CiEdit } from "react-icons/ci";
import { useDispatch } from "react-redux";
import { setOpenCustDetailsState, setPopOpenState } from "../../store/slices/customSlice";
import { fetchUserAllCustomers } from "../../service/userCustomerDetailsService";

type CustomerDetails = {
    id:number,
  type: string;
  value: string;
};

const CustomerDetails: React.FC = () => {
  const dispatch = useDispatch()
  const userDetails: CustomerDetails[] = [
    {
        id:1,
    type: "Name",
    value: "Hemanth",
  },
  {
    id:2,
    type: "Gender",
    value: "Male",
  },
  {
    id:3,
    type:"Contact",
    value: "8688055328",
  }

]

  return (
    <section className="">
      <OrderHeaderBar title="Customer Details">
        <CiEdit className="ml-2 text-DARKEST_GREY_L2 text-xl  cursor-pointer " onClick={()=>{
          
    
          
          dispatch(setOpenCustDetailsState(true))
          dispatch(setPopOpenState(true))
        }} />
      </OrderHeaderBar>
      <div className="px-3 lg:px-10 pt-3  lg:pt-4">
        {userDetails.map((item,key)=>(
            <div className="text-DARKEST_GREY_L1 flex items-center lg: pb-2 gap-8">
                <h2 className="w-14">
                    {item.type}
                </h2>
                <p className="font-medium">
                    {item.value}
                </p>
            </div>
        ))}
      </div>
    </section>
  );
};

export default CustomerDetails;