import React,{useState} from 'react';
import { fetchSalonSlots } from '../../service/salonSlotsService';
import { useDispatch, useSelector } from 'react-redux';
import { calculateSlotsFromTotalTime } from '../../utils/slotSchedule';
import { RootState } from '../../store/store';
import { setDateState } from '../../store/slices/bookingSlice';

interface ISlotDateProps {
    slotDates:{
        date:number|string,
        day:string,
        fullDate:string
    }
}

const SlotDateCard : React.FC<ISlotDateProps> =({slotDates})=>{

    const dispatch = useDispatch()

    const [selectedTime, setSelectedTime] = useState<string>('');
    const professionalId = useSelector((state:RootState)=>state.booking.selectedProfessionId||'')
    const totalTimeInMinutes = useSelector((state:RootState)=>state.userCart.totaltime);
    const slotDurationInMinutes = 15; // 15 minutes

    const { numberOfSlots, remainingMinutes } = calculateSlotsFromTotalTime(
        +totalTimeInMinutes,
        slotDurationInMinutes,
    );
    const selectedDate = useSelector((state:RootState)=>state.booking.selectedDate)

  

    return (
        // numberOfSlots:number,professional_id:number,slot_dates:string
        <div className={`border ${slotDates.fullDate==selectedDate?'bg-DARKEST_GREY_L4':''}  border-DARKEST_GREY_L3 rounded-2xl mt-2 lg:px-5 lg:py- 1 flex flex-col justify-center w-14 h-14 md:w-16 md:h-16`} onClick={()=>{
            setSelectedTime(slotDates.fullDate)
            fetchSalonSlots(dispatch,numberOfSlots,+professionalId,slotDates.fullDate)
         dispatch(setDateState(slotDates.fullDate))

        }} >
          
           {/* <p className={` ${ !['Sun','Sat'].includes(slotDates.day)&&'hidden'} bg-DARKEST_GREY_L4 text-xs rounded-full m-auto lg:m-0 w-max lg: px-3 py-1`}>
           ₹100 
           </p> */}
           
            <h2 className="text-texthero font-semibold text-center pt-1">
              {slotDates.date}
            </h2>
            <h4 className="text-DARKEST_GREY_L1 text-center">
                {slotDates.day}
            </h4>
           
        </div>
    )
}

export default SlotDateCard;