// src/store/slices/salonSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SalonState {
  categories: string[];
  activeCategory: string;
}

const initialState: SalonState = {
  categories: [],
  activeCategory: 'Recommended',
};

const salonSlice = createSlice({
  name: 'salon',
  initialState,
  reducers: {
    setCategories(state, action: PayloadAction<string[]>) {
      state.categories = action.payload;
    },
    setActiveCategory(state, action: PayloadAction<string>) {
      state.activeCategory = action.payload;
    },
  },
});

export const { setCategories, setActiveCategory } = salonSlice.actions;
export default salonSlice.reducer;
