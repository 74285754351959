import React from  'react';
import { IoMdClose } from "react-icons/io";

interface IStatusProps {
    statusObj:{
     
        title:string,
        description:string,
        btnActions?:Array<{
            id:number,
            btnName:string,
            btnAction:()=>void,
            custClassName?:string,

        }>,
        imgUrl:string, 
        isSpec?:boolean

    } 

}

const BookingStatusCard :React.FC<IStatusProps> = ({statusObj})=>{
    return (
        <div className="bg-white relative w-max rounded-2xl h-48 md:h-96 xl:h-[27rem] px-6 md:px-16 lg:px-20 xl:px-24 flex md:gap-8 lg:gap-28 items-center">
 
            
            {/* <IoMdClose className='absolute top-6 right-9 text-3xl'/> */}
            
            <div className="">
                <h1 className={`${statusObj.isSpec?'text-green':'text-texthero'} text-xl md:text-2xl lg:text-3xl   xl:text-[32px] font-medium text-center md:text-start`}>
                {statusObj.title}
                </h1>
                <p className={`${statusObj.isSpec?'text-green':'text-DARKEST_GREY_L1'} text-xs md:text-sm  pt-3 xl:pt-4 w-60 md:w-72  text-center md:text-start`}>
                 {statusObj.description}
                </p>
                <div className="flex items-center gap-2 md:gap-3 pt-5 lg:pt-6">
                    {statusObj.btnActions&&statusObj.btnActions.map((btn, index) => (
                      <button className={`${btn.custClassName} px-3 md:px-5 xl:px-6  py-2 xl:py-3  border rounded w-max text-sm md:text-base `}  onClick={btn.btnAction} >
                        {btn.btnName}
                      </button>
                    ))}
                </div>
            </div>
           
            <img src={statusObj.imgUrl} alt="Booking Status" className='hidden md:flex md:w-52 xl:w-60' />

 
        </div>

    )
}

export default BookingStatusCard;