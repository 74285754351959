import React, { useEffect, useState } from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { ISalonDetailsProps } from "../../types/salonProfileTypes";
import { IoStar } from "react-icons/io5";
import GreetingMessageCard from "../Ui/GreetingMessageCard";
import RectangleCard from "../Ui/RectangleCard";
import SalonStatus from "./SalonStatus";
import { getUserName } from "../../lib/userService";
import CustServicePopUpCard from "../Ui/CustServicePopUpCard";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import CustPackagePopUpCard from "../Ui/CustPackagePopUpCard";

const SalonDetails: React.FunctionComponent<ISalonDetailsProps> = (
  salonInfo: ISalonDetailsProps
) => {
  const [openPop, setOpenPop] = useState<boolean>(false);
  const [statusCard, setStatusCard] = useState<boolean>(true);
 
 

 


  useEffect(() => {
    setTimeout(() => {
      setStatusCard(false);
    }, 3000);
  }, []);
 
 

  useEffect(() => {
    if (openPop && statusCard === false) {
      setTimeout(() => {
        setOpenPop(false);
      }, 3000);
    }
  }, [openPop, statusCard]);

  const handlePopModel = () => {
    setOpenPop(!openPop);
  };
  let isPopActive = useSelector((state:RootState)=>state.custom.openSharePop)

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error}</p>;
  return (
    <React.Fragment>
    
      <section className={`${isPopActive?'-z-50':'z-auto'} w-full flex relative flex-col md:flex-row justify-between  flex-grow-0 `}>
        <div className={` md:w-full md:flex  items-start `}>
          <div className="flex">
            <div className="flex flex-col">
              <div className="flex items-center   gap-2 lg:gap-4">
                <h1 className="w-max text-texthero md:text-[20px] lg:text-[28px] xl:text-[32px] font-semibold lg:pb-1">
                  {salonInfo.salonName}
                </h1>
                <div className="relative w -full h-full">
                  <IoMdInformationCircleOutline
                    onClick={handlePopModel}
                    className="text-xl xl:text-2xl font-bold z-40 cursor-pointer text-DARKEST_GREY_L2 my-auto h-full"
                  />
                  </div>
                <div className="  w- full h- full">
                  <div
                    className={`${
                      openPop ? "" : "hidden"
                    } absolute -right-1 top-2.5  md:left-2 lg:left-[17rem] xl:left-80   lg:-top-6 z-50 w-full h-full`}
                  >
                    <RectangleCard />
                  </div>
                  <div
                    className={`${
                      !statusCard ? "hidden" : "flex justify-center md:block"
                    } w-full absolute left-36  md:left-3 top-3 md:-top-2   `}
                  >
                    <SalonStatus />
                  </div>
                </div>
              </div>
              <div className="text-xs md:text-sm lg:text-base text-DARKEST_GREY_L2">
                <div className="w-max  flex items-center gap-4 pt-1 md:pt-2 lg:pt-0">
                  <h4 className="">{salonInfo.salonAddress}</h4>
                  <a
                    href={salonInfo.mapLink}
                    target="_blank"
                    className="text-blue"
                    rel="noreferrer"
                  >
                    Get Directions
                  </a>
                </div>
                <div className="  flex   items-center">
                  {salonInfo.specilization &&
                    salonInfo.specilization.map((item, key) => (
                      <div
                        className="flex items-center text-xs md:text-sm lg:text-base pt-1 lg:pt-0.5   "
                        key={key}
                      >
                        <p className="w-max capitalize">{item}</p>
                        <p
                          className={`${
                            key + 1 === salonInfo.specilization.length
                              ? "hidden"
                              : "block"
                          } px-1`}
                        >
                          |
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="flex flex-1"></div> */}
          {/* <div className="flex flex-1"/> */}
          <div className="absolute right-0 top-0 md:relative -z-20 flex flex-col justify-end mr-0 float-right items-end w-full lg:mr-4">
            <div className="flex items-center   justify-end rounded-md bg-texthero text-white lg: gap-1 py-0.5 lg:pb-0.5 lg:mt-2.5 px-1 md:px-2 ">
              <p className=" text-xs    md:text-sm lg:text-lg xl:text-[24px] font-semibold">
                {salonInfo.rating}
              </p>
              <IoStar className="text-hero2 text-sm md:text-lg xl:text-2xl" />
            </div>
            <p className="text-blue text-[8px]  md:text-[11px] lg:text-xs   pt-1">
              {salonInfo.reviews} Reviews
            </p>
          </div>
        </div>
        <div className="hidden md:block float-right  ">
          {/* <GreetingMessageCard   /> */}
        </div>
      </section>
    </React.Fragment>
  );
};

export default SalonDetails;
