import React,{useState,useEffect} from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // import styles
import { getSalonGallary } from '../../lib/salonProfileService';

import { IoMdStar } from 'react-icons/io';
import { ButtonUi } from './SalonGallary';
import { IoShareOutline } from "react-icons/io5";
import { IoApps } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { setOpenSharePopState, setPopOpenState } from '../../store/slices/customSlice';

interface ISalonGallaryProps {
  gallery:string[]
}


const SalonMobGallary:React.FC<ISalonGallaryProps>  = ({gallery}) => {
  const starVal = Array(5).fill(0); // Adjust the star array if necessary
 const dispatch = useDispatch()
  let isPopActive = useSelector((state: RootState) => state.custom.isPopOpen);



  useEffect(() => {
    const galleryData = getSalonGallary();
    // setGallery(galleryData);
  }, []);

  return (
    <div className={`${isPopActive ? "-z-50" : ""}    md:hidden pt-5 review-carousel-container h-max`}>
      <Carousel 
        showArrows={false} 
        showThumbs={false} 
        showStatus={false} 
        infiniteLoop={true} 
        autoPlay={true} 
        interval={5000}
        emulateTouch={true}
        centerMode={true}
        centerSlidePercentage={100} // Show 3 items per slide
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          const indicatorClasses = isSelected ? 'indicator selected' : 'indicator';
          return (
            <li
              className={indicatorClasses}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        {gallery.map((item,index)=>(
            <div className="" key={index}>
                <img src={item} alt="saloon Gallary" className='rounded-lg h-[150px]' />
            </div>
        ))}

        {/* {reviews.map((review, key) => (
          <div key={key} className="h-80 m-autom flex items-center justify-center pb-16 ">
            <div className="gradient-border px-5 lg:px-9 py-5 lg:py-7 lg:w-[400px] xl:w-[420px] h-[200px] lg:h-[240px]">
              <p className="lg:w-[350px] h-[114px] lg:h-[112px] text-sm lg:text-base text-texthero text-center">
                {review.review}
              </p>
              <div className="flex items-center justify-center pt-4 lg:gap-2 lg:pb-3">
                <img src={review.profileImage} alt={review.userName} className="w-6 h-6 object-cover rounded-full" />
                <p className="text-DARKEST_GREY_L1  ">
                  {review.userName}
                </p>
              </div>
              <div className="flex items-center justify-center">
                {starVal.map((_, starKey) => (
                  <IoMdStar 
                    key={starKey} 
                    className={`lg:text-2xl ${starKey + 1 <= review.rating ? 'text-hero2' : 'text-DARKEST_GREY_L3'}`} 
                  />
                ))}
              </div>
            </div>
          </div>
        ))} */}
      </Carousel>
      <div className="relative">

      <div className="absolute right-3 bottom-14 cursor-pointer " 
      onClick={()=>{
        dispatch(setPopOpenState(true))
        dispatch(setOpenSharePopState(true))

      }}>
            <ButtonUi>
              <IoShareOutline  className="w-3 md:w-max"/>
              <h3 className="hidden md:block text-xs font-medium">Share</h3>
            </ButtonUi>
          </div>
          <div className="absolute right-3 bottom-3  ">
            <ButtonUi>
            <IoApps className="w-3 md:w-max" />
            <h3 className="hidden md:block  text-xs font-medium">see All Photos</h3>
            </ButtonUi>
          </div>
          </div>
    </div>
  );
};

export default SalonMobGallary;
