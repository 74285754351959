import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { setSalonSlots } from '../store/slices/salonSlotsSlice';

const API_ENDPOINT = process.env.REACT_APP_API_URL;

export const fetchSalonSlots = async (dispatch: Dispatch,numberOfSlots:number,professional_id:number,slot_dates:string): Promise<void> => {
  try {

  
    const response: AxiosResponse = await axios.get(
     `${API_ENDPOINT}/booking/getAvailableSlots?number_of_slots=${numberOfSlots}&professional_id=${professional_id}&date=${ slot_dates}
 `,
      {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
          Authorization: 'your_token_here',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
        },
      }
    );

    const availableSlotsArray = response.data.availableSlotsArray;

    // Dispatch the available slots to Redux store
    dispatch(setSalonSlots(availableSlotsArray));

  } catch (error) {
    console.error('Error fetching salon slots:', error);
  }
};
