import React,{useState,useEffect} from 'react'
import { ReveiwFeedback } from './ReviewFeedback';
import { ServiceSummary } from './ServiceSummary';
import { getBrandColor } from '../../lib/brandService';
import { ReviewTags } from './ReviewTags';
import SpinWheel from './SpinWheel';
import { useDispatch, useSelector } from 'react-redux';
import { setShowSpinState } from '../../store/slices/customSlice';
import { RootState } from '../../store/store';


const ServiceReviewMain : React.FC = ()=>{
    const dispatch = useDispatch();

    const ratingValue = useSelector((state:RootState)=>state.custom.serviceRating)
    const brandColor = getBrandColor();
    const [count, setCount] = useState(10);
    const [timerEnded, setTimerEnded] = useState(false);
  
    useEffect(() => {
      const timerInterval = setInterval(() => {
        if (count > 0&&  +ratingValue>0) {
         setCount(count - 1);
        } else {
          setTimerEnded(true);
          clearInterval(timerInterval);
          dispatch(setShowSpinState(true))
        }
      }, 1000);
      return () => clearInterval(timerInterval);
    }, [count,ratingValue]);
  
   return (
        <section className="">
            <div className="text-center text-texthero">
                <h1 className="md:text-2xl lg:text-3xl  font-bold py-2  md:py-3  lg:py-4">
                Rate and Review Your Service
                </h1>
                <h4 className="font-semibold md:text-lg  " style={{
                    color:brandColor
                }}>
                    with Lucas Salon
                </h4>
            </div>
            <hr className="text-DARKEST_GREY_L4 mt-3 md:mt-5 " />
            <div className="layout layout-md layout-lg layout-xl h-full " >
                <div className=" flex items-center justify-center md:gap-14 lg:gap-56">

                <div className="">

                <ReveiwFeedback/>
                <ReviewTags/>
                {(timerEnded&&+ratingValue>0&&count>0)  &&
        <p className='text-center md:text-start  py-3 '>Elapsed time: {Math.floor(count)} seconds</p>
      }
                </div>
               
                <ServiceSummary/>
                </div>

            <SpinWheel/>

            <h2 className="text-center md:text-start md:text-2xl lg:text-3xl font-medium text-DARKEST_GREY_L1 pt-3 md:pt-6">
            You have already rated the trip. We hope to see you soon.
            </h2>
            </div>

            
        </section>
    )
}

export default ServiceReviewMain;