import { ISalonInfo } from "../types/salonServicesTypes";
import img1 from '../assets/productBrands/img1.png'
import img2 from '../assets/productBrands/img2.png'
import img3 from '../assets/productBrands/img3.png'
import img4 from '../assets/productBrands/img4.png'
import { FaLinkedin, FaYoutube, FaFacebook } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { IoLogoWhatsapp } from "react-icons/io";

 
export const salonInfoData:ISalonInfo[] = [{
    id:1,
    salonName:'About Lucas Salon & Academy',
    salonFeatures:[
        {
            id:1,
            featureName:'Overview',
            featureDescription:['Nestled in the heart of the city, Lucas Salon is a true oasis of style, sophistication, and pampering in the world of beauty and grooming. As a premium establishment, Lucas Salon caters to the discerning tastes of both men and women, offering an exquisite range of services that redefine the standards of luxury.'],
            socialMediaLinks:[
                {
                id:1,
                Icon:FaLinkedin,
                link:'https://www.linkedin.com/company/stylin-official'
            },
                {
                id:2,
                Icon:RiInstagramFill,
                link:'https://www.instagram.com/stylin.app/'
            },
                {
                id:3,
                Icon:FaYoutube,
                link:'http://www.youtube.com/@stylin_media'
            },
                {
                id:4,
                Icon:FaFacebook,
                link:''
            },
            {
                id:5,
                Icon:IoLogoWhatsapp,
                link:''
            }
        ],
        },
        {
            id:2,
            featureName:'Services',
            featureDescription:['Hair Care/ Treatment', 'Hair Removal', 'Massage'],
            serviceFor:['Men Only','Female Only','Unisex']
        },
        {
            id:3,
            featureName:'Address',
            featureDescription:["4th Floor, Fortune Atrium, Road No. 36, Jubilee Hills, opp. చట్నీస్ రెస్టారెంట్, Hyderabad, Telangana 500033"]
        },
        {
            id:4,
            featureName:'Timings',
            featureDescription:['Open from 10:00 am to 8:00 pm on all days except Tuesday']
        },
        {
            id:5,
            featureName:'Payment Methods',
            featureDescription:['Cash and Cards accepted ','Digital payments accepted']
        },
        {
            id:6,
            featureName:'Certifications',
            featureDescription:['Hair Care/ Treatment', 'Hair Removal', 'Massage']
        },
        {
            id:7,
            featureName:'Salon Amenities',
            featureDescription:['air Conditioning', 'Parking', 'Wi-Fi', 'Music']
        }, 
        {
            id:8,
            featureName:'Brand Products Used',
            featureDescription:[img1,img2,img3,img4,img1,img2,img3]
        }

    ]

}]